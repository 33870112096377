import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Grid,
  Button,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import styles from './View.module.css';
import {Link} from 'react-router-dom';
import {Add as AddIcon, Delete as DeleteIcon} from '@material-ui/icons';

const Questionnaires = (props) => {
  const {team, questionnaireDeleteFun} = props;
  const questionnaires = props.questionnaires || [];

  return (
      <React.Fragment>
        <Typography component="h3" variant="h5" gutterBottom>Fill out
          questionnaires</Typography>
        <Grid container>

          <Grid item xs={12}>
            <Box pt={2} pb={2}>
              <Typography variant={'body2'} className={styles.helpText}>
                To fill out a questionnaire, click on the + sign. After
                submission
                it
                will appear in the list below. Once you submitted it, you will
                not
                be able
                to edit, but still to delete it. Once you request the Analysis,
                you can only
                delete the whole team.</Typography>
            </Box>
          </Grid>
          {team.closed === false ?
              <Grid item xs={12}>
                <Box mt={2} mb={3} display="flex" justifyContent="center">
                  <Button
                      className={styles.addQuestionnaireButton}
                      aria-label="add"
                      component={Link}
                      to={'/team/' + team.hash +
                      '/questionnaires/create'}
                  >
                    <AddIcon/> Give your answers
                  </Button>
                </Box>
              </Grid>
              :
              null}
          <Grid item xs={12}>
            <Typography variant="subtitle1">Submitted answers</Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell align="right">Date</TableCell>
                  {team.closed === false ?
                      <TableCell align="right">Actions</TableCell>
                      :
                      null
                  }
                </TableRow>
              </TableHead>
              <TableBody>
                {questionnaires.map(questionnaire => (
                    <TableRow key={questionnaire.hash}>
                      <TableCell scope="row">
                        {questionnaire.name}
                      </TableCell>
                      <TableCell align="right">
                        {new Date(questionnaire.created_at).toLocaleString()}
                      </TableCell>
                      {team.closed === false ?
                          <TableCell align="right">
                            <Button variant="contained" color="secondary"
                                    onClick={() => questionnaireDeleteFun(
                                        questionnaire.hash,
                                    )}
                            >
                              <DeleteIcon className={styles.rightIcon}/>
                            </Button>
                          </TableCell>
                          :
                          null
                      }
                    </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </React.Fragment>
  );
};

Questionnaires.propTypes = {
  team: PropTypes.object.isRequired,
  questionnaireDeleteFun: PropTypes.func.isRequired,
  questionnaires: PropTypes.arrayOf(PropTypes.object),
};

export default Questionnaires;