import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  FormHelperText,
  FormControlLabel,
  Typography,
} from '@material-ui/core';
import {TextField, CheckboxWithLabel} from 'formik-material-ui';
import ActionButton from '../../ActionButton';
import ReCAPTCHA from 'react-google-recaptcha';
import {RECAPTCHA_SITE_KEY} from '../../../constants';
import styles from './Create.module.css';
import {FastField, Form, Formik} from 'formik';

export const Create = (props) => {
  const {
    handleSubmitFun,
    validationSchema,
    reCaptchaRef,
  } = props;

  const CreateTeamForm = ({
    handleSubmit,
    setSubmitting,
    handleChange,
    handleBlur,
    errors,
    touched,
    isSubmitting,
    status,
    submitForm,
    setFieldValue,
  }) => (
      <React.Fragment>
        <ReCAPTCHA
            theme="light"
            size="invisible"
            ref={reCaptchaRef}
            sitekey={RECAPTCHA_SITE_KEY}
            onChange={
              (response) => {
                setFieldValue('recaptcha', response);
                submitForm();
              }
            }
        />

        <Form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant={'h5'} gutterBottom>Name of your team
                *</Typography>
              <FastField
                  name="alias"
                  required
                  component={TextField}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant={'h5'} gutterBottom>Your Email *</Typography>
              <FastField
                  name="email"
                  required
                  component={TextField}
              />
            </Grid>
            <Grid item xs={12} ml="10px">
              <FormControlLabel
                  name="accept_terms"
                  control={
                    <FastField component={CheckboxWithLabel}/>
                  }
                  label={
                    <Typography variant='body2' component="span">
                      I accept the <a href='/tos' target="_blank">
                      terms of service</a> *
                    </Typography>}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant='body2' component="p" className={styles.privacyText}>
                By continuing you consent to the processing of your personal data. More information about how we handle
                your data can be found in the <a className={styles.privacyText} href='/privacy' target="_blank">privacy policy</a>.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormHelperText><Typography color="error" component="span">{
                errors.accept_terms &&
                touched.accept_terms &&
                String(errors.accept_terms)}</Typography>
              </FormHelperText>
            </Grid>
            <Grid item xs={12}>
              <Grid container direction="column"
                    justify="flex-start"
                    alignItems="center">
                <Grid item xs={8} lg={6}>
                  {status && status.msg ? (
                      <Typography color='error'>API
                        Error: {status.msg}</Typography>
                  ) : null}
                </Grid>
                <Grid item xs={8} lg={6}>
                  <ActionButton
                      variant="contained"
                      color="primary"
                      type="submit"
                      loading={isSubmitting}
                      disabled={
                        isSubmitting ||
                        Object.keys(errors).length !== 0 ||
                        Object.keys(touched).length === 0
                      }
                  >
                    Create team
                  </ActionButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      </React.Fragment>
  );

  CreateTeamForm.propTypes = {
    handleSubmit: PropTypes.func,
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
    values: PropTypes.object,
    errors: PropTypes.object,
    status: PropTypes.object,
    touched: PropTypes.object,
    isSubmitting: PropTypes.bool,
  };

  return (
      <div>
        <Grid container
              direction="column"
              justify="flex-start"
              alignItems="center"
              spacing={3}>

          <Grid item xs={12} md={6} lg={4}>
            <img className={styles.teamImage} alt="Team working on table"
                 src={process.env.PUBLIC_URL + '/imgs/home/newteam.png'}/>
          </Grid>

          <Grid item xs={12} md={6}>
            <Formik
                initialValues={{
                  alias: '',
                  email: '',
                  accept_terms: false,
                }}
                onSubmit={handleSubmitFun}
                validationSchema={validationSchema}
                isInitialValid={false}
                component={CreateTeamForm}
            />
          </Grid>
        </Grid>
      </div>
  );
};

Create.propTypes = {
  handleSubmitFun: PropTypes.func.isRequired,
  validationSchema: PropTypes.object,
  reCaptchaRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({current: PropTypes.any}),
  ]),
};

export default Create;