import React from 'react';
import {FastField} from 'formik';
import {
  Grid,
  Typography,
  FormControlLabel,
  FormHelperText,
} from '@material-ui/core';
import {TextField, CheckboxWithLabel} from 'formik-material-ui';
import PropTypes from 'prop-types';
import ActionButton from '../../ActionButton';

const Details = props => {

  const {errors, touched, isSubmitting, status} = props;
  return (
      <Grid container spacing={5} direction="column" alignItems="flex-start"
            key="tabpanel-0">
        <Grid item xs={12}>
          <Typography variant={'h5'} gutterBottom>Your name *</Typography>
          <FastField
              name="name"
              required
              component={TextField}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant={'h5'} gutterBottom>Your Email *</Typography>
          <FastField
              name="email"
              required
              component={TextField}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
              name="accept_terms"
              control={
                <FastField component={CheckboxWithLabel}/>
              }
              labelPlacement="end"
              label={
                <Typography variant='h5' component="span">
                  I accept the <a href='/tos' target="_blank">
                  Terms of Service</a> and <a href='/privacy'
                                              target="_blank">
                  Privacy Policy</a> *
                </Typography>}
          />
          <FormHelperText><Typography color="error" component="span">{
            errors.accept_terms &&
            touched.accept_terms &&
            String(errors.accept_terms)}</Typography>
          </FormHelperText>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1" color="secondary">
            Warning: You wont be able to update, only delete
            and recreate!
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <ActionButton
              style={{height: '75px'}}
              variant="contained"
              color="primary"
              type="submit"
              loading={isSubmitting}
              disabled={
                isSubmitting ||
                Object.keys(errors).length !== 0 ||
                Object.keys(touched).length === 0
              }
          >
            Submit Questionnaire
          </ActionButton>
        </Grid>
        <Grid item xs={12}>
          {status && status.msg ? (
              <Typography color='error'>API
                Error: {status.msg}</Typography>
          ) : null}
        </Grid>
      </Grid>
  );
};

Details.propTypes = {
  status: PropTypes.object,
  touched: PropTypes.object,
  errors: PropTypes.object,
  isSubmitting: PropTypes.bool,
};

export default Details;