import React from 'react';
import PropTypes from 'prop-types';
import {Button, CircularProgress} from '@material-ui/core';

const SpinnerAdornment = props =>
    <CircularProgress
        style={{marginLeft:'10px'}}
        size={20}
    />

const ActionButton = (props) => {
  const {
    children,
    loading,
    ...rest
  } = props;
  return (
      <Button {...rest}>
        {children}
        {loading && <SpinnerAdornment {...rest} />}
      </Button>
  );
};


ActionButton.propTypes = {
  children: PropTypes.any,
  loading: PropTypes.bool,
};

export default ActionButton;