import React from 'react';
import PropTypes from 'prop-types';
import styles from './View.module.css';
import {SCORE_BAD_LIMIT, SCORE_GOOD_LIMIT} from '../../constants';

const ScoreEmoji = ({score}) => {
  let image_source = process.env.PUBLIC_URL + '/imgs/score/';
  let image_color = 'white';
  let title = 'Smiley ';
  if (score < SCORE_BAD_LIMIT) {
    image_source += 'neutral.svg';
    image_color = '#EA497A';
    title += 'thinking';
  } else if (score <= SCORE_GOOD_LIMIT) {
    image_source += 'good.svg';
    image_color = '#FFD450';
    title += 'smiling';
  } else {
    image_source += 'great.svg';
    image_color = '#87E748';
    title += 'starstruck';
  }

  return (
      <img
          className={styles.scoreEmoji}
          style={{background: image_color}}
          src={image_source}
          title={title}
          alt={title}
      />
  );
};

ScoreEmoji.propTypes = {
  score: PropTypes.number,
};

export default ScoreEmoji;