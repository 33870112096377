import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import styles from './DonateButton.module.css';
import Button from '@material-ui/core/Button';
import GiftcardIcon from '@material-ui/icons/CardGiftcard';
import {
  DONATIONS_PAYPAL_RECIPIENT,
  DONATIONS_PAYPAL_TEXT,
} from '../../constants';

const useStyles = makeStyles(theme => ({
  button: {
    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    color: 'white',
    margin: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
}));

const DonateButton = () => {

  const classes = useStyles();

  return (
      <div className={styles['donate-button']}>
        <form action="https://www.paypal.com/cgi-bin/webscr" method="post"
              target="_blank">
          <input type="hidden" name="cmd" value="_donations"/>
          <input type="hidden" name="business"
                 value={DONATIONS_PAYPAL_RECIPIENT}/>
          <input type="hidden" name="item_name" value={DONATIONS_PAYPAL_TEXT}/>
          <input type="hidden" name="currency_code" value="USD"/>
          <Button
              variant="contained"
              type="submit"
              className={classes.button}
              alt="Donate with PayPal button">
            Donate
            <GiftcardIcon className={classes.rightIcon}/>
          </Button>
        </form>
      </div>
  );
};

export default DonateButton;