import React from 'react';
import PropTypes from 'prop-types';
import questionnaire_items from '../../constants/Questionnaire/V1';
import {apiAdviceCreate, apiTeamGet} from '../../api/AlignaClient';
import {Create} from '../../components/Advice/Create';
import * as Yup from 'yup';
import {updateUser} from '../../store/actions/User';
import {connect} from 'react-redux';

class AdviceCreateContainer extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            user_hash: '',
            team_hash: '',
            question_alias: '',
            question_data: null,
            team: null,
        };
    }

    getQuestion = (question_alias) => {
        for (let [, category] of Object.entries(questionnaire_items)) {
            const questionData = category.filter(
                obj => obj.alias === question_alias)[0];
            if (questionData) return questionData;
        }
        return null;
    };

    adviceValidationSchema = Yup.object()
                                .shape({
                                    name: Yup.string()
                                             .max(20, 'Too long!')
                                             .matches(/^[A-Za-z0-9 ]+$/, {message: 'Please only use letters, numbers and spaces'}),
                                    team_active: Yup.boolean(),
                                    advice: Yup.string()
                                               .min(1, 'Too short!')
                                               .max(1200, 'Too long!')
                                               .required('Please fill out this field')
                                               .matches(/^[\w\d\s.,;:!?\-#*„“‚‘'"([\])/+&^]{1,1200}$/gm,
                                                   {message: 'Please only use numbers, letters, spaces and .,;:!?-#*\'"([])/+&^'}),
                                });

    createAdvice = async (values, actions) => {
        apiAdviceCreate(this.state.user_hash, this.state.team_hash,
            this.state.question_alias, values).then(() => {
            this.props.history.push('/thanks');
        }).catch(() => {
            actions.setStatus({msg: 'Something went wrong'});
        }).finally(() =>
            actions.setSubmitting(false),
        );
    };

    getTeam = async (team_hash) => {
        apiTeamGet(team_hash).then((team) => {
            this.setState({
                ...this.state,
                'team': team,
            });
        }).catch(() => {
            this.props.history.push('/404');
        }).finally(() => {
        });
    };

    componentDidMount() {
        const {user_hash, team_hash, question_alias} = this.props.match.params;
        this.setState({
            ...this.state,
            'user_hash': user_hash,
            'team_hash': team_hash,
            'question_alias': question_alias,
            'question_data': this.getQuestion(question_alias),
        });
        this.props.updateUser({'hash': user_hash});
        this.getTeam(team_hash);
    }

    render() {
        return (
            <Create handleSubmitFun={this.createAdvice}
                    validationSchema={this.adviceValidationSchema}
                    team={this.state.team}
                    questionData={this.state.question_data}
            />
        );
    }
}

AdviceCreateContainer.propTypes = {
    updateUser: PropTypes.func.isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            user_hash: PropTypes.string.isRequired,
            team_hash: PropTypes.string.isRequired,
            question_alias: PropTypes.string.isRequired,
        }),
    }).isRequired,
    history: PropTypes.object.isRequired,
};

const mapDispatchToProps = (dispatch) => {
    return {updateUser: user => dispatch(updateUser(user))};
};

export default connect(null, mapDispatchToProps)(AdviceCreateContainer);