import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

const DialogAnalysis = (props) => {
  const {
    confirmAnalysisState,
    closeTeamFun,
    closeConfirmationFun,
  } = props;

  return (
      <Dialog open={confirmAnalysisState} onClose={closeConfirmationFun}
              aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Confirm analysis</DialogTitle>
        <DialogContent>
          <DialogContentText>
            If you continue with the analysis, you can not add or delete new
            questionnaires. Continue only if all team members already submitted.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeConfirmationFun} color="secondary">
            Cancel
          </Button>
          <Button onClick={closeTeamFun} color="primary">
            Continue
          </Button>
        </DialogActions>
      </Dialog>
  );
};

DialogAnalysis.propTypes = {
  confirmAnalysisState: PropTypes.bool,
  closeTeamFun: PropTypes.func.isRequired,
  closeConfirmationFun: PropTypes.func.isRequired,
};

export default DialogAnalysis;