import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import TeamView from '../../components/Team/View';
import {addTeam, removeTeam} from '../../store/actions/Teams';
import {
  apiTeamClose,
  apiTeamGet,
  apiQuestionnairesGet,
  apiQuestionnaireDelete,
  apiTeamDelete,
} from '../../api/AlignaClient';

class View extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      team: null,
      questionnaires: null,
      hash: null,
      closing: false,
      deleting: false,
      confirmAnalysisState: false,
      confirmDeletionState: false,
      loading: false,
    };
  }

  goToAnalysis = () => {
    this.props.history.push('/team/' + this.state.hash + '/analysis');
  };

  confirmAnalysis = () => {
    this.setState({confirmAnalysisState: true});
  };

  closeConfirmation = () => {
    this.setState({confirmAnalysisState: false});
  };

  confirmDeletion = () => {
    this.setState({confirmDeletionState: true});
  };

  closeDeletion = () => {
    this.setState({confirmDeletionState: false});
  };

  closeTeam = () => {
    if (this.state.submitting) return;
    this.setState({closing: true, confirmAnalysisState: false});
    apiTeamClose(this.state.hash).then(() => {
      // disable spinner
      this.setState({closing: false});
      //this.loadData(this.state.hash);
      this.goToAnalysis();
    }).catch((err) => {
      // remove team from store if it has been deleted in the meantime
      if (err.response.status === 404) {
        this.props.removeTeamFromStore(this.state.hash, this.props.history,
            '/404');
      }
    }).finally(() => {
      this.setState({closing: false});
    });
  };

  loadData = (hash) => {
    this.setState({loading: true});
    Promise.all([
      apiTeamGet(hash),
      apiQuestionnairesGet(hash),
    ]).then(([team, questionnaires]) => {
      this.props.addTeamToStore(team);
      this.setState({
        ...this.state,
        'team': team,
        'hash': team.hash,
        'questionnaires': questionnaires,
      });
    }).catch((teamGetError, questionnairesGetError) => {
      if (teamGetError != null) {
        this.props.removeTeamFromStore(hash, this.props.history, '/404');
      }
    }).finally(() => {
      this.setState({loading: false});
    });
  };

  deleteTeam = () => {
    apiTeamDelete(this.state.hash).then(() => {
      this.props.removeTeamFromStore(this.state.hash, this.props.history, '/');
    }).catch((err) => {
      console.log(err);
    });
  };

  deleteQuestionnaire = (hash) => {
    apiQuestionnaireDelete(hash).then(() => {
      this.loadData(this.state.hash);
    }).catch((err) => {
      console.log(err);
      // TODO: questionnaires failed to load?
    });
  };

  componentDidMount() {
    const {hash,invite} = this.props.match.params;
    this.loadData(hash);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const {hash} = nextProps.match.params;
    if (hash !== prevState.hash && hash !== null) {
      return {hash: hash};
    } else return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.hash !== this.state.hash) {
      this.loadData(this.state.hash);
    }
  }

  render() {
    return (
        <TeamView team={this.state.team}
                  questionnaires={this.state.questionnaires}
                  questionnaireDeleteFun={this.deleteQuestionnaire}
                  closeTeamFun={this.closeTeam}
                  closing={this.state.closing}
                  confirmAnalysisFun={this.confirmAnalysis}
                  confirmAnalysisState={this.state.confirmAnalysisState}
                  closeConfirmationFun={this.closeConfirmation}
                  confirmDeletionState={this.state.confirmDeletionState}
                  deleteTeamFun={this.deleteTeam}
                  confirmDeletionFun={this.confirmDeletion}
                  closeDeletionFun={this.closeDeletion}
                  goToAnalysisFun={this.goToAnalysis}
                  loading={this.state.loading}
        />
    );
  }
}

View.propTypes = {
  location: PropTypes.object,
  addTeamToStore: PropTypes.func.isRequired,
  removeTeamFromStore: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      hash: PropTypes.string.isRequired,
    }),
  }).isRequired,
  history: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {teamList: state.teamReducer};
};

const mapDispatchToProps = (dispatch) => {
  return {
    addTeamToStore: team => dispatch(addTeam(team)),
    removeTeamFromStore: (hash, history, url) => {
      dispatch(removeTeam(hash));
      history.replace(url);
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(View));