import React from 'react';
import PropTypes from 'prop-types';
import {MobileStepper, Button} from '@material-ui/core';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

const Stepper = (props) => {

  const {nrSteps, value, handleNextFun, handleBackFun} = props;

  return (
      <MobileStepper
          variant="dots"
          steps={nrSteps}
          position="static"
          activeStep={value}
          style={{ backgroundColor: "transparent" }}
          nextButton={
            <Button variant="contained" color="primary" onClick={handleNextFun}
                    disabled={value === nrSteps-1}>
              Next
              <KeyboardArrowRight/>
            </Button>
          }
          backButton={
            <Button size="small" onClick={handleBackFun}
                    disabled={value === 0}>
              <KeyboardArrowLeft/>
              Back
            </Button>
          }
      />

  );
};

Stepper.propTypes = {
  nrSteps: PropTypes.number.isRequired,
  value: PropTypes.number,
  handleNextFun: PropTypes.func.isRequired,
  handleBackFun: PropTypes.func.isRequired,
};

export default Stepper;