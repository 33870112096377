import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Typography
} from '@material-ui/core';

export const TabPanel = props => {
  const {children, value, index} = props;
  return (
      <Typography
          component="div"
          role="tabpanel"
          hidden={value !== index}
          id={`scrollable-force-tabpanel-${index}`}
          aria-labelledby={`scrollable-force-tab-${index}`}
      >
        <Box>{children}</Box>
      </Typography>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default TabPanel;