export const QUESTION_DISPLAY_TYPE_RADIO = 'QUESTION_DISPLAY_TYPE_RADIO';
export const QUESTION_DISPLAY_TYPE_CHECKBOXES = 'QUESTION_DISPLAY_TYPE_CHECKBOXES';
export const QUESTION_DISPLAY_TYPE_TEXT = 'QUESTION_DISPLAY_TYPE_TEXT';
export const QUESTION_DISPLAY_TYPE_CHECKBOX = 'QUESTION_DISPLAY_TYPE_CHECKBOX';
export const QUESTION_TYPE_ORDINAL = 'QUESTION_TYPE_ORDINAL';
export const QUESTION_TYPE_CATEGORICAL = 'QUESTION_TYPE_CATEGORICAL';
export const QUESTION_TYPE_DICHOTOMOUS = 'QUESTION_TYPE_DICHOTOMOUS';
export const QUESTION_TYPE_SUM = 'QUESTION_TYPE_SUM';
export const QUESTION_TYPE_MYBR = 'QUESTION_TYPE_MYBR';
export const QUESTION_TYPE_NOTRATED = 'QUESTION_TYPE_NOTRATED';

const questionnaire_items = {
  /* Personality */
  'personality': [
    {
      alias: 'mb_ei',
      type: QUESTION_TYPE_MYBR,
      display_type: QUESTION_DISPLAY_TYPE_RADIO,
      heading: 'After a very long an hard week, how do you spend your weekend?',
      options: [
        {
          handle: 'e',
          label: 'Calling your friends and going out to a restaurant, theatre or club.',
        },
        {
          handle: 'i',
          label: 'Switching my phone off and watching a good movie or having a ' +
                 'bath with a book. ',
        },
      ],
    },
    {
      alias: 'mb_sn',
      type: QUESTION_TYPE_MYBR,
      display_type: QUESTION_DISPLAY_TYPE_RADIO,
      heading: 'Which description fits you more?',
      options: [
        {
          handle: 's',
          label: 'Paying attention to details is crucial and help assessing real ' +
                 'situation here and now.',
        },
        {
          handle: 'n',
          label: 'Relying more on intuition is important to create the tomorrow‘s world.',
        },
      ],
    },
    {
      alias: 'mb_tf',
      type: QUESTION_TYPE_MYBR,
      display_type: QUESTION_DISPLAY_TYPE_RADIO,
      heading: 'You get an offer from a competitor with a higher salary, though ' +
               'your boss wants to promote you after she retires. How will you ' +
               'make your decision?',
      options: [
        {
          handle: 't',
          label: 'Acquiring all relevant information and carefully weighting ' +
                 'pros and cons of the situation.',
        },
        {
          handle: 'f',
          label: 'Listening to my gut feeling and follow my heart.',
        },
      ],
    },
    {
      alias: 'mb_jp',
      type: QUESTION_TYPE_MYBR,
      display_type: QUESTION_DISPLAY_TYPE_RADIO,
      heading: 'One week is left before your close friends’ wedding. ' +
               'How are the preparations going ?',
      options: [
        {
          handle: 'j',
          label: 'I took care of everything including choosing the musicians ' +
                 'writing my speech and ironed my suit.',
        },
        {
          handle: 'p',
          label: 'Preparation is for beginners as best things happen spontaneously.',
        },
      ],
    },
    {
      alias: 'roles',
      type: QUESTION_TYPE_NOTRATED,
      display_type: QUESTION_DISPLAY_TYPE_CHECKBOXES,
      heading: "Which are the functional roles you are capable of playing in a startup?",
      options: [
        {handle: 'operations', label: 'Operations'},
        {handle: 'business_development', label: 'Business development'},
        {handle: 'product_management', label: 'Product management'},
        {handle: 'software_engineering', label: 'Software engineering'},
        {handle: 'dev_ops', label: 'DevOps'},
        {handle: 'business_strategy', label: 'Business strategy'},
        {handle: 'hr', label: 'Human resources'},
        {handle: 'sales', label: 'Sales'},
        {handle: 'marketing', label: 'Marketing'},
        {handle: 'design_ui', label: 'Design/UI'},
        {handle: 'research', label: 'Research'},
        {handle: 'analytics', label: 'Analytics'},
        {handle: 'finance', label: 'Finance'},
        {handle: 'support', label: 'Support'}
      ]
    },
  ],
  /* Motivation */
  'motivation': [
    {
      alias: 'company_size',
      type: QUESTION_TYPE_ORDINAL,
      display_type: QUESTION_DISPLAY_TYPE_RADIO,
      heading: 'How big do you want your company to be in year 5?',
      options: [
        {handle: '0', label: 'Boutique'},
        {handle: '1', label: 'Small company'},
        {handle: '2', label: 'Big company'},
        {handle: '3', label: 'Unicorn'},
      ],
    },
    {
      alias: 'company_style',
      type: QUESTION_TYPE_DICHOTOMOUS,
      display_type: QUESTION_DISPLAY_TYPE_RADIO,
      heading: 'On which side of the spectrum would you position yourself?',
      options: [
        {
          handle: '0',
          label: 'Sustainable business spinning of decent cash forever',
        },
        {
          handle: '1',
          label: 'High growth with high risk and the option of a huge liquidity event',
        },
      ],
    },
    {
      alias: 'own_financial_goals',
      type: QUESTION_TYPE_ORDINAL,
      display_type: 'QUESTION_DISPLAY_TYPE_RADIO',
      heading: 'What are your personal financial goals in an exit case?',
      options: [
        {
          handle: '0',
          label: 'Earned play-money but you still have to work (less than $1M)',
        },
        {handle: '1', label: 'Early retirement ($1-10M)'},
        {handle: '2', label: 'Buying Amazon and some Island (more than $10M)'},
      ],
    },
    {
      alias: 'why',
      type: QUESTION_TYPE_ORDINAL,
      display_type: QUESTION_DISPLAY_TYPE_RADIO,
      heading: 'Why do you want to start a company?',
      options: [
        {handle: '0', label: 'To improve the world'},
        {handle: '1', label: 'To build something value-adding from scratch'},
        {handle: '2', label: 'To enjoy challenging tasks'},
        {handle: '3', label: 'To get rich'},
      ],
    },
    {
      alias: 'life_project',
      type: QUESTION_TYPE_ORDINAL,
      display_type: QUESTION_DISPLAY_TYPE_RADIO,
      heading: 'Is your startup a temporary limited project or your life’s work?',
      options: [
        {handle: '0', label: 'It is my life and my baby until retirement'},
        {
          handle: '1',
          label: 'If it outgrows me after a time, I am willing to leave',
        },
        {handle: '2', label: 'I want to exit as soon as possible'},
      ],
    },
    {
      alias: 'plan_b',
      type: QUESTION_TYPE_DICHOTOMOUS,
      display_type: QUESTION_DISPLAY_TYPE_RADIO,
      heading: 'Do you have a plan B?',
      options: [
        {handle: '0', label: 'Yes'},
        {handle: '1', label: 'No'},
      ],
    },
  ],
  /* Time and Money */
  'time_and_money': [
    {
      alias: 'phase_research',
      type: QUESTION_TYPE_ORDINAL,
      display_type: QUESTION_DISPLAY_TYPE_RADIO,
      heading: 'If there is no clear idea, how long should your research phase last?',
      options: [
        {handle: '0', label: 'Maximum 1 month'},
        {handle: '1', label: 'Less than 6 months'},
        {handle: '2', label: 'Less than 1 year'},
        {handle: '3', label: 'More than 1 year '},
      ],
    },
    {
      alias: 'phase_validation',
      type: QUESTION_TYPE_ORDINAL,
      display_type: QUESTION_DISPLAY_TYPE_RADIO,
      heading: 'How long should your validation phase for your idea last?',
      options: [
        {handle: '0', label: 'Maximum 1 month'},
        {handle: '1', label: 'Less than 6 months'},
        {handle: '2', label: 'Less than 1 year'},
        {handle: '3', label: 'More than 1 year '},
      ],
    },
    {
      alias: 'phase_mvp',
      type: QUESTION_TYPE_ORDINAL,
      display_type: QUESTION_DISPLAY_TYPE_RADIO,
      heading: 'What is the time horizon to build the MVP (minimum viable product, which is the minimum product you need, to sell your idea to customers)?',
      options: [
        {handle: '0', label: 'Maximum 1 month'},
        {handle: '1', label: 'Less than 6 months'},
        {handle: '2', label: 'Less than 1 year'},
        {handle: '3', label: 'More than 1 year '},
      ],
    },
    {
      alias: 'lowest_cash',
      type: QUESTION_TYPE_ORDINAL,
      display_type: QUESTION_DISPLAY_TYPE_RADIO,
      heading: 'What is the lowest net income per month you need for living?',
      options: [
        {handle: '0', label: 'Less than $1500'},
        {handle: '1', label: '$1500-2500'},
        {handle: '2', label: '$2500-4000'},
        {handle: '3', label: 'More than $4000'},
      ],
    },
    {
      alias: 'lowest_cash_duration',
      type: QUESTION_TYPE_ORDINAL,
      display_type: QUESTION_DISPLAY_TYPE_RADIO,
      heading: 'How long are you willing to live at your lowest net income?',
      options: [
        {handle: '0', label: 'Maximum 1 month'},
        {handle: '1', label: 'Less than 6 months'},
        {handle: '2', label: 'Less than 1 year'},
        {handle: '3', label: 'More than 1 year '},
      ],
    },
  ],
  /* Commitment */
  'commitment': [
    {
      alias: 'work_hours_earlystage',
      type: QUESTION_TYPE_CATEGORICAL,
      display_type: QUESTION_DISPLAY_TYPE_RADIO,
      heading: 'What are your expected work hours in a 5 day work week when getting the company off the ground (years 0-3)?',
      options: [
        {handle: '0', label: 'Less than 40h'},
        {handle: '1', label: '40 - 60h'},
        {handle: '2', label: 'More than 60h'},
      ],
    },
    {
      alias: 'work_hours_latestage',
      type: QUESTION_TYPE_CATEGORICAL,
      display_type: QUESTION_DISPLAY_TYPE_RADIO,
      heading: 'What are your expected work hours in a 5 day work week when settling into a rhythm when company is stabilizing (years 3-5)?',
      options: [
        {handle: '0', label: 'Less than 40h'},
        {handle: '1', label: '40 - 60h'},
        {handle: '2', label: 'More than 60h'},
      ],
    },
    {
      alias: 'decision_making',
      type: QUESTION_TYPE_ORDINAL,
      display_type: QUESTION_DISPLAY_TYPE_RADIO,
      heading: 'Are there any clear expectations you have about decision-making (me vs. consensus)?',
      options: [
        {handle: '0', label: 'I decide inside my area'},
        {handle: '1', label: 'Every founder’s opinion must be heard'},
        {handle: '2', label: 'Whatever is the best for the company'},
        {handle: '3', label: 'Always consensus'},
      ],
    },
    {
      alias: 'other_commitments',
      type: QUESTION_TYPE_SUM,
      display_type: QUESTION_DISPLAY_TYPE_CHECKBOXES,
      heading: 'Which additional sacrifices would you contribute for the startup’s success?',
      options: [
        {handle: 'cash', label: 'Cash'},
        {handle: 'relocation', label: 'Relocation'},
        {handle: 'opportunities', label: 'Giving up other job opportunities'},
        {handle: 'social_cost', label: 'Social costs'},
        {handle: 'further', label: 'Further'},
      ],
    },
    {
      alias: 'full_commitment',
      type: QUESTION_TYPE_CATEGORICAL,
      display_type: 'QUESTION_DISPLAY_TYPE_RADIO',
      heading: 'Under which conditions would you work full-time for your startup?',
      options: [
        {handle: '0', label: 'If it feels good'},
        {handle: '1', label: 'Same idea in mind'},
        {handle: '2', label: 'Great product'},
        {handle: '3', label: 'External funding acquired'},
        {handle: '4', label: 'Paying customers'},
      ],
    },
    {
      alias: 'resons_to_leave',
      type: QUESTION_TYPE_CATEGORICAL,
      display_type: QUESTION_DISPLAY_TYPE_CHECKBOXES,
      heading: 'Under which conditions would you leave your startup?',
      options: [
        {handle: 'onlyone_working', label: 'Being the only one working'},
        {handle: 'unequal_equity', label: 'Unequal equity distribution'},
        {handle: 'disputes', label: 'Personal disputes'},
        {handle: 'values', label: 'Different values in business'},
      ],
    },
  ],
  /* Team Work */
  'team_work': [
    {
      alias: 'others_describe_you',
      type: QUESTION_TYPE_CATEGORICAL,
      display_type: QUESTION_DISPLAY_TYPE_CHECKBOXES,
      heading: 'How would your coworkers describe you as a worker?',
      options: [
        {handle: 'entrepreneurial', label: 'Entrepreneurial'},
        {handle: 'detail_oriented', label: 'Detail-oriented'},
        {handle: 'collaborative', label: 'Collaborative'},
        {handle: 'creative', label: 'Creative'},
        {handle: 'inspiring', label: 'Inspiring'},
        {handle: 'disciplined', label: 'Disciplined'},
        {handle: 'empathetic', label: 'Empathetic'},
        {handle: 'passionate', label: 'Passionate'},
        {handle: 'flexible', label: 'Flexible'},
        {handle: 'enduring', label: 'Enduring'},
        {handle: 'respectful', label: 'Respectful'},
      ],
    },
    {
      alias: 'coping_with_stress',
      type: QUESTION_TYPE_CATEGORICAL,
      display_type: QUESTION_DISPLAY_TYPE_RADIO,
      heading: 'How do you cope with stress?',
      options: [
        {handle: '0', label: 'Working harder and more'},
        {handle: '1', label: 'Take a break then start slowly'},
        {handle: '2', label: 'Panic!'},
      ],
    },
    {
      alias: 'conflict_resolution',
      type: QUESTION_TYPE_ORDINAL,
      display_type: QUESTION_DISPLAY_TYPE_RADIO,
      heading: 'How do you personally deal with conflict?',
      options: [
        {handle: '0', label: 'Proactive communication is key'},
        {
          handle: '1',
          label: 'Harmony driven trying to circumvent any conflicts',
        },
        {handle: '2', label: 'Waiting passively and then exploding'},
      ],
    },
    {
      alias: 'culture_in_team',
      type: QUESTION_TYPE_CATEGORICAL,
      display_type: QUESTION_DISPLAY_TYPE_RADIO,
      heading: 'What is most important in your company culture you want to create?',
      options: [
        {handle: '0', label: 'Extended family atmosphere'},
        {handle: '1', label: 'Structure and control'},
        {handle: '2', label: 'Risk taking and rewards'},
        {handle: '3', label: 'Result orientation and efficiency'},
      ],
    },
    {
      alias: 'estimation',
      type: QUESTION_TYPE_NOTRATED,
      display_type: QUESTION_DISPLAY_TYPE_RADIO,
      heading: 'How much do you have the feeling to be aligned?',
      options: [
        {handle: '0', label: 'We have joint and clear vision'},
        {handle: '1', label: 'Somewhat neutral'},
        {handle: '2', label: 'Wonder we see each other'},
      ],
    },
  ],
};

export default questionnaire_items;