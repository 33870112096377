import React from 'react';
import {FastField} from 'formik';
import {CheckboxWithLabel, RadioGroup} from 'formik-material-ui';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {Radio, Box, Paper} from '@material-ui/core';
import {
    QUESTION_DISPLAY_TYPE_RADIO,
    QUESTION_DISPLAY_TYPE_CHECKBOXES,
    QUESTION_DISPLAY_TYPE_TEXT,
    QUESTION_DISPLAY_TYPE_CHECKBOX,
} from '../constants/Questionnaire/V1';
import {Grid, Typography} from '@material-ui/core';

export const render_question = (namespace, question) => {
    const headline = <Typography variant={'h5'} color="primary" gutterBottom
                                 style={{maxWidth: '800px'}}>
        {question.heading}
    </Typography>;
    let content = null;
    switch (question.display_type) {
        case QUESTION_DISPLAY_TYPE_RADIO:
            content = to_radio_list(namespace + '.' + question.alias,
                question.options);
            break;
        case QUESTION_DISPLAY_TYPE_CHECKBOXES:
            content = to_checkbox_map(namespace + '.' + question.alias,
                question.options);
            break;
        case QUESTION_DISPLAY_TYPE_TEXT:
            content = null;
            break;
        case QUESTION_DISPLAY_TYPE_CHECKBOX:
            content = null;
            break;
        default:
            break;
    }
    return <Grid item xs={12}
                 key={`question-${namespace}-${question.alias}`}>
        <Paper square elevation={3}>
            <Box p={2}>
                {headline}
                {content}
            </Box>
        </Paper>
    </Grid>;
};

export const to_checkbox_map = (namespace, items) => {
    return items.map((item) =>
        <FormControlLabel
            style={{
                display: 'flex',
                alignContent: 'center',
                justifyContent: 'flex-start',
                marginLeft: 0
            }}
            key={namespace + '.' + item.handle}
            name={namespace + '.' + item.handle}
            label={item.label}
            control={
                <FastField component={CheckboxWithLabel}/>
            }
        />,
    );
};

export const to_radio_list = (namespace, items) => {
    const options = items.map((item) =>
        <FormControlLabel
            key={item.handle}
            value={item.handle}
            label={item.label}
            control={<Radio/>}
            style={{
                paddingTop: '5px',
                paddingBottom: '5px'
            }}
        />,
    );

    return <FastField
        name={namespace}
        label={namespace}
        component={RadioGroup}
    >
        {options}
    </FastField>;
};
