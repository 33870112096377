import React from 'react';
import {
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
  EmailIcon,
  EmailShareButton,
} from 'react-share';
import styles from './ShareButtons.module.css';

const shareUrl = 'https://aligna.team';
const share_tweet = 'To all fellow #startup founders: Check out this awesome founding team alignment tool @alignateam';
const email_subject = 'Aligna.Team prevents personal misalignment from killing your early-stage startup';
const share_email_body =
    'Hi, \n\n' +
    'Check this awesome cofounder alignment tool ' + shareUrl +
    ' that prevents early-stage startup breakups. It is super easy and free to use!\n\n' +
    'Cheers,\n\n';

const ShareButtons = () => {
  return (
      <div className={styles.icons}>
        <TwitterShareButton
            url={shareUrl}
            title={share_tweet}
            openWindow={true}
            className={styles.socialIcon}>
          <TwitterIcon size={30}/>
        </TwitterShareButton>
        <LinkedinShareButton
            url={shareUrl}
            windowWidth={750}
            windowHeight={600}
            openWindow={true}
            className={styles.socialIcon}>
          <LinkedinIcon size={30}/>
        </LinkedinShareButton>
        <EmailShareButton
            url=''
            subject={email_subject}
            body={share_email_body}
            openWindow={true}
            className={styles.socialIcon}>
          <EmailIcon size={30}/>
        </EmailShareButton>
      </div>
  );
};

export default ShareButtons;