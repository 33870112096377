import React from 'react';
import TitleComponent from '../TitleComponent';
import Typography from '@material-ui/core/Typography';
import {Grid} from '@material-ui/core';

const LegalNotice = () => {
  return (
      <Grid container style={{textAlign: 'justify'}}>
        <Grid item xs={12} md={6}>
        <TitleComponent title={'Legal Notice'}/>

        <Typography component="h1" variant="h4" gutterBottom>Legal Notice</Typography>

        <Typography variant="h5" gutterBottom>Information pursuant to § 5 TMG</Typography>
        <p>MunichTrading UG (haftungsbeschränkt)<br/>
          Gundelindenstr. 5<br/>
          D-80805 München</p>
        <p>HRB 220190<br/>
          Registration court: Amtsgericht München
        </p>
        <p><strong>Represented by the CEO:</strong><br/>
          Linus Kohl
        </p>

        <Typography variant="h5" gutterBottom>Contact</Typography>
        <p>
          Phone: +49 (0)89 381 537 90<br/>
          Telefax: +49 (0)89 365 033<br/>
          E-Mail: legal@munichtrading.com
        </p>

        <Typography variant="h5" gutterBottom>VAT ID</Typography>
        <p>Sales tax identification number according to § 27 a of the Sales Tax
          Law:<br/>
          DE301386117</p>

        <Typography variant="h5" gutterBottom>
          Responsible for the content according to § 55(2) RStV
        </Typography>
        <p>Linus Kohl<br/>
          Gundelindenstr. 5<br/>
          D-80805 München
        </p>

        <Typography variant="h6" gutterBottom>Liability for Contents</Typography>
        <p>
        As service providers, we are liable for own contents of these
        websites according to Sec. 7, paragraph 1
        German Telemedia Act (TMG). However, according to Sec. 8 to 10 German
        Telemedia Act (TMG), service
        providers are not obligated to permanently monitor submitted or stored
        information or to search for
        evidences that indicate illegal activities.</p> <p>Legal obligations
        to removing information or to
        blocking the use of information remain unchallenged. In this case,
        liability is only possible at the time of
        knowledge about a specific violation of law. Illegal contents will be
        removed immediately at the time we get
        knowledge of them.</p> <h3>Liability for Links</h3> <p>Our offer
        includes links to external third party
        websites. We have no influence on the contents of those websites,
        therefore we cannot guarantee for those
        contents. Providers or administrators of linked websites are always
        responsible for their own contents.</p>
        <p>The linked websites had been checked for possible violations of law
          at the time of the establishment of
          the link. Illegal contents were not detected at the time of the
          linking. A permanent monitoring of the
          contents of linked websites cannot be imposed without reasonable
          indications that there has been a
          violation of law. Illegal links will be removed immediately at the
          time we get knowledge of them.</p>
        <Typography variant="h6" gutterBottom>Copyright</Typography>
        <p>
        Contents and compilations published on these websites by the
        providers are subject to
        German copyright laws. Reproduction, editing, distribution as well as
        the use of any kind outside the scope
        of the copyright law require a written permission of the author or
        originator. Downloads and copies of these
        websites are permitted for private use only.<br/> The commercial use
        of our contents without permission of
        the originator is prohibited.</p> <p>Copyright laws of third parties
        are respected as long as the contents
        on these websites do not originate from the provider. Contributions of
        third parties on this site are
        indicated as such. However, if you notice any violations of copyright
        law, please inform us. Such contents
        will be removed immediately.</p>
      </Grid>
      </Grid>
  );
};

export default LegalNotice;
