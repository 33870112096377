import {
  USER_UPDATE
} from '../../constants/ActionTypes';

const initialState = {
  user: {
    hash: '',
  },
};

function userReducer(state = initialState, action) {
  switch (action.type) {
    case USER_UPDATE: {
      return Object.assign({}, state, {
        user: action.user
      });
    }
    default:
      return state;
  }
}

export default userReducer;