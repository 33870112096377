import {TRACKING_SET} from '../../constants/ActionTypes';

const initialState = {
  tracking: true,
};

function trackingReducer(state = initialState, action) {
  switch (action.type) {
    case TRACKING_SET:
      return Object.assign({}, state, {tracking: action.payload});
    default:
      return state;
  }
}

export default trackingReducer;