import React from 'react';
import {Provider as ReduxProvider} from 'react-redux';
import {BrowserRouter as Router} from 'react-router-dom';
import {store, persistor} from './store';
import routes from './routes';
import './index.css';
import 'typeface-roboto';
import {ThemeProvider} from '@material-ui/styles';
import theme from './theme';
import {PersistGate} from 'redux-persist/integration/react';
import {ScrollToTop} from './utils/scroll';

/**
 * App
 * @return {ThemeProvider} App
 */
function App() {
  return (
      <ThemeProvider theme={theme}>
        <PersistGate loading={null} persistor={persistor}>
          <ReduxProvider store={store}>
            <Router>
              <ScrollToTop />
              {routes}
            </Router>
          </ReduxProvider>
        </PersistGate>
      </ThemeProvider>
  );
}

export default App;
