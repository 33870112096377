import React from 'react';
import TitleComponent from '../TitleComponent';
import {Grid, Typography} from '@material-ui/core/';

const Tos = () => {
  return (
      <Grid container direction="column" justify="flex-start"
            alignItems="flex-start" style={{textAlign: 'justify'}}>
        <TitleComponent title={'Terms of service'}/>
        <Grid item xs={12} md={6}>
          <Typography variant="h4" gutterBottom component="h1">
            Terms of service</Typography>
          <Typography variant="subtitle1" gutterBottom>Last updated 08 November
            2019</Typography>
          <Typography variant="h5">1. Introduction</Typography>
          These Terms of Service are for teams only and apply to your team’s use
          of Aligna.Team and all content and services made available through it
          (including the purchase of paid for products/content and services)
          (the “Website”); by using the Website, you (as administrator (“Team
          Leader”) for your team (“Team”)) and your Team agree to these Terms of
          Service in full. If you and/or your Team disagree with these Terms of
          Service or any part of these Terms of Service, please do not register,
          subscribe or use the Website.<br/>
          By using the Website on behalf of your Team (which you will identify
          when you sign up with us) you confirm that you are entitled to do so
          and that you have authority to bind your Team (including any legal
          entity behind your Team) to its terms. References to “you” in these
          terms mean you and your Team and any legal entity behind them.
          The Website uses cookies. By using the Website and agreeing to these
          Terms of Service, you consent to our use of cookies in accordance with
          the terms of our Privacy Notice. Personal data you provide to us will
          be handled in accordance with our Privacy Notice.<br/>
          You must be over the age of 18 years to use the Website as a Team
          Leader and subscribe to our premium services. Team members must be at
          least 13 years old (or the minimum age of consent in the relevant
          country). It is your responsibility to provide details of team members
          (“Team Members”) to us and have them register with us so that we can
          permit them access to Aligna.Team so you can use Aligna.Team within
          your team.
          You are responsible for how your Team and your Team Members use the
          Website.<br/>
          The Website is designed for business users, not consumers, and these
          terms are drafted with business users (including charities) in mind.
          If you are a consumer, you are not entitled to use the Website.
          Please read – how our products (which include our Website) are
          intended to be used, and what are your obligations in relation to
          their use:<br/>
          Our products and Website are provided for information purposes only to
          help understand and improve personal information, team culture,
          motivation, communication, and similar aspects. They are not provided
          for medical, employment, or psychological assessment purposes (e.g. to
          recruit, screen, manage, or assess others in an employment
          context). Also:<br/>
          <ul>
            <li>Use of our products and Website assumes that those using or
              participating are willing to do so and that the facilitator has
              read the facilitation guide and conducts any workshop and uses any
              results in a non-discriminatory, lawful and sensitive manner. You
              are responsible for and will comply with all employment, data
              protection and any other relevant laws and regulations in having
              your Team Members use the Website.
            </li>
            <li>You undertake to keep individual results confidential and to
              respect the fact that an individual’s results are confidential to
              them and that they have the right to keep them confidential and
              not disclose them to others whether inside or outside your
              organization.
            </li>
            <li>Our products and Website are only useful if those participating
              are honest about their teamwork styles and preferences. If that is
              not the case (for example, they feel they need to respond in a
              particular way in order for that to be acceptable to their manager
              or organization), then any output will be flawed. In any event, we
              have no liability for the output of any of our services and
              products and for any use you may make of such outputs.
            </li>
            <li>Any decisions that you make once you have used our products and
              Website are for you alone and we will not be liable for the
              consequences of any such decisions.
            </li>
            <li>Our Website provides information and materials relating to
              personality traits for general guidance and information only. This
              information cannot provide (and is not intended to provide)
              comprehensive coverage of all relevant issues nor advice on
              specific issues. Our content should always be used in conjunction
              with other information, advice and training.
            </li>
            <li>We make no claims or representations in relation to the
              emotional, health or commercial benefits of using our products and
              the Website and the information provided on the Website is no
              substitute for professional, medical or psychiatric advice where
              applicable.
            </li>
            <li>Please note that these Terms of Service may be amended from time
              to time. Notification of any changes will be made by posting new
              terms onto the Website. In continuing to use the Website you
              confirm that you accept the then current terms and conditions in
              full at the time you use the Website.
            </li>
          </ul>

          <Typography variant="h5">2. Sign up</Typography>
          <p>
            You can access many areas of the Website without registering or
            subscribing but if you wish to view certain content or access
            certain services, you may be required to signup with the
            Website.<br/>
            When you register with the Website we will ask you for certain
            personal information. Any personal information that you provide to
            use will be handled in accordance with our Privacy Notice.<br/>
            If you register with the Website you will be required to keep your
            team link to access information in future.<br/>
            You agree that all personal information that you supply to us will
            be accurate, complete and kept up to date at all times. We may use
            the
            information provided to us to contact you in accordance with our
            Privacy Notice.<br/>
            We reserve the right to suspend or cancel any signup (and any use of
            our Website) at any time and for any reason.
          </p>

          <Typography variant="h5">3. Personality Assessments</Typography>
          <p>You will be able to use the Website to take team alignment
            assessments
            (the “Assessments”) and arrange for your team members to do so. The
            results of the Assessments are automatically analyzed. We may also
            record and use your answers and results for research and quality
            improvement purposes.</p>

          <Typography variant="h5">4. Purchase of Digital Content and
            Services</Typography>
          <p>We offer digital content and subscription services (the “Products”)
            for purchase on the Website. They are provided as described there.
            We
            reserve the right to change the descriptions and Products offered
            from
            time to time.<br/>
            Our Products are licensed, not sold, to you. They remain our
            property.
            The intellectual property rights (including copyright) in the
            Products
            remain with us and/or our authors/licensors as applicable. Our
            products may include digital rights management information and
            technology, which you agree not to tamper with or remove, and which
            is
            intended to deter or prevent misuse of our intellectual property.
            At the time you place any order for which payment is required you
            must
            give authority for payment. We may take payment from you at any time
            between you placing the order and us providing the services or you
            requesting the download of digital content.<br/>
            Your order is an offer to purchase from us. There will be no
            contract
            of any kind between you and us unless and until we actually take
            payment from you, at which point we will e-mail you with
            confirmation
            of the contract and acceptance of your order. At any point up until
            then, we may decline to supply the services or digital content to
            you
            without giving any reason.<br/>
            The prices payable for the items that you order are clearly set out
            on the Website. If, by mistake, we have underpriced an item, we will
            not be liable to supply that item to you at the stated price
            provided
            that we notify you before we dispatch or make available the item
            concerned. Payments are made through the Website electronically.
            When you pay
            by credit or debit card you consent to us carrying out certain
            checks
            which include obtaining authorization from your card issuer to
            ensure you have adequate funds and for security reasons. This may
            involve
            validating your name, address and other personal information with
            your bank or credit reference agencies if necessary.<br/>
            For our subscription-based services, payments are due for any month
            or year on the same or closest date to the day you made your first
            payment. Your subscription will renew automatically. You can turn
            off auto-renewal at any point.<br/>
            All prices shown are in Euro (€EUR), unless specified otherwise, and
            include VAT, where applicable.<br/>
          </p>

          <Typography variant="h5">5. Cancellations and Refunds</Typography>
          <p>
            You can cancel the renewal of your premium subscription at any time.
            Your account will retain premium access until the end of that
            billing
            period.<br/>
            We do not offer refunds or credits for partial months of service, or
            refunds for months unused with an open account.<br/>
            You do have a right to cancel a contract with us for digital content
            within 14 days without giving any reason, provided that you have not
            downloaded or otherwise accessed any of our assessments or content.
            The day of the formation of the contract will be the day that you
            create the Team in question on our website.<br/>
            To exercise the right to cancel, you must inform us, e-mail
            support@aligna.team, of your decision to cancel this contract by a
            clear statement.<br/>
            To meet the cancellation deadline it is sufficient for you to send
            your communication concerning your exercise of the right to cancel
            before the cancellation period has expired.
          </p>

          <Typography variant="h5">6. Limited Warranties</Typography>
          <p>
            We do not warrant the completeness or accuracy of the information
            published on this Website or in any of the Products that we may sell
            to you, nor do we commit to ensuring that the Website remains
            available or that the material on the Website is kept up to date.
            The
            Website is provided on an ‘as is’ basis and we reserve the right to
            suspend the Website and our provision of any services at any time
            without notice.<br/>
            To the maximum extent permitted by applicable law, we exclude all
            representations, warranties and conditions relating to this Website
            and the use of this Website (including, without limitation, any
            warranties implied by law in respect of satisfactory quality,
            fitness
            for purpose and/or the use of reasonable care and skill). Where you
            use the Website or the Products you confirm that you are solely
            responsible for any actions or decisions made as a result of such
            use.
            We make no representations as to the accuracy of any Product
            including
            personality assessments.<br/>
            Nothing in this Website or our Products should be construed as
            medical, psychological, recruitment, business, or any other advice.
            All materials are for informational purposes only.<br/>
            Where the Website contains links to third party websites we make no
            representation in relation to the content contained on third party
            websites and you agree to use these websites at your own risk. We
            will
            not form part of any agreement or contract with any third party
            website you visit through our website.
          </p>
          <Typography variant="h5">7. License to Use Website</Typography>
          <p>Unless otherwise stated, we or our licensors own the intellectual
            property rights in the Website and material on the Website,
            including
            all Products, including but not limited to copyright and associated
            moral rights, trade marks, get-up, and unregistered rights,
            goodwill,
            know-how, software, database rights, and all other intellectual
            property rights. Subject to the license below, all these
            intellectual
            property rights are exclusively reserved. Where the Website and any
            Products feature the intellectual property of third parties which we
            do not own this is clearly stated.<br/>
            You may view, download for caching purposes only, and print pages
            from
            the Website for your own personal use only within the Team that
            registered with us (and not for resale or to provide services to
            others), subject to the restrictions set out below and elsewhere in
            these Terms of Service. Your right to use such materials will cease
            when the relevant subscription ends or where we terminate your
            rights
            to use the Website and you will then promptly delete or destroy all
            such materials.<br/>
            Without prior consent from us you must not:<br/>
            <ol type="a">
              <li>reproduce, translate or reverse engineer material from
                this Website (including republication on another Website or
                other reproduction or communication of our Products without
                consent);
              </li>
              <li>sell, rent or sub-license material or Products from the
                Website;
              </li>
              <li>communicate any material or Products from the Website to
                the public;
              </li>
              <li>reproduce, duplicate, copy or otherwise exploit material
                or Products on our Website for a commercial purpose other than
                for the internal business purposes of your organization/team
                (and not any other organization/team);
              </li>
              <li>edit or otherwise modify any material or Products on the
                Website;
              </li>
              <li>use the Products or Website to provide services to persons
                outside your Team;
              </li>
              <li>redistribute material or Products from this Website except
                for content specifically and expressly made available for
                redistribution;<br/>or
              </li>
              <li>reproduce any intellectual property rights of third
                parties on our Website without the consent of those parties.
              </li>
            </ol>
            By submitting any data and other information to our Website
            (“Data”) (and having your Team Members do so), you grant us a
            perpetual, royalty-free, irrevocable, non-exclusive, worldwide,
            unrestricted license to reproduce, use, perform, prepare derivative
            works, distribute copies or publicly display the Data in any medium
            and for any purpose (including commercial purposes), and also to
            authorize others to do so. We may also analyze your Data for
            research purposes.<br/>
            In so doing we will comply with all applicable data privacy laws
            and we will treat as confidential any information that may
            reasonably be considered to be confidential.
          </p>

          <Typography variant="h5">8. Acceptable Use</Typography>
          <p>You must not use the Website in any way that causes, or may cause,
            damage to the Website or impairment of the availability or
            accessibility of the Website; or in any way which is unlawful,
            illegal, fraudulent or harmful, or in connection with any unlawful,
            illegal, fraudulent or harmful purpose or activity.<br/>
            You must not use the Website to copy, store, host, transmit, send,
            use, publish or distribute any material which consists of (or is
            linked to) any spyware, computer virus, Trojan horse, worm,
            keystroke
            logger, rootkit or other malicious computer software.<br/>
            You must not conduct any systematic or automated data collection
            activities (including, without limitation, scraping, data mining,
            data extraction and data harvesting) on or in relation to the
            Website without our express written consent. You must not infringe
            the rights in any Products that are sold through the Website.</p>

          <Typography variant="h5">9. Unsolicited Idea Submissions</Typography>
          <p>
            Aligna.Team and its employees do not accept or consider unsolicited
            ideas,
            including but not limited to creative proposals, ideas for new,
            enhanced or improved products, technologies or services, marketing
            campaigns, promotions, processes, materials, artwork, plans,
            strategies, theories or product names. Please do not submit any
            unsolicited ideas, articles, original creative artwork, suggestions
            or other works in any form to us or any of our employees.<br/>
            The sole purpose of this section is to avoid potential disputes when
            our products, website content or business strategies might seem
            similar to unsolicited ideas submitted to us. If, despite our
            request that you not send us any ideas or suggestions, you still
            choose to submit them, then regardless of what your letter, comment,
            e-mail or any other form of communication says, the following terms
            will apply to your submissions:<br/>
            <ol>
              <li>You agree and understand that:
                <ol>
                  <li>your submissions will
                    automatically become the sole property of Aligna.Team,
                    without
                    any compensation to you, and Aligna.Team will have no
                    obligation
                    of any kind to you or anyone else with respect to your
                    submissions;
                  </li>
                  <li>Aligna.Team will be free to use or redistribute the
                    submissions in any way and for any purpose, including but
                    not limited to developing, acquiring or
                    advertising products or services competitive with those
                    mentioned by you;
                  </li>
                  <li> there will be no obligation for Aligna.Team to review
                    your submissions or develop your ideas; and (4) there will
                    be no obligation to keep any part of your submission
                    confidential.
                  </li>
                </ol>
              </li>
              <li>No confidential relationship or obligation of secrecy
                will be created between you and Aligna.Team by your submission
                and
                its consideration by us – everything you submit shall be deemed
                to be non-confidential and non-proprietary. You agree and
                understand that Aligna.Team shall be free to use any information
                you
                submit on an unrestricted basis, whether or not Aligna.Team was
                working on similar ideas, products or technology prior to
                receiving your submission.
              </li>
              <li>
                We do welcome your feedback – if you want to send us your
                feedback, please hello@aligna.team. However, please do not
                include any ideas that this policy will not permit us to accept
                or consider.
              </li>
            </ol>
          </p>

          <Typography variant="h5">10. Limitations and Exclusions of
            Liability</Typography>
          <p>
            Nothing in these Terms of Service will:<br/>
            <ol type="a">
              <li>limit or exclude our or your liability for death or personal
                injury resulting from negligence;
              </li>
              <li>limit or exclude our or your liability for fraud or fraudulent
                misrepresentation;
              </li>
              <li>limit any of our or your liabilities in any way that is not
                permitted under applicable law; or
              </li>
              <li>exclude any of our or your liabilities that may not be
                excluded under applicable law.
              </li>
            </ol>
            The limitations and exclusions of liability set out in this Section
            and elsewhere in these Terms of Service:<br/>
            <ol type="a">
              <li>are subject to the preceding paragraph; and</li>
              <li>govern all liabilities arising under these Terms of Service or
                in relation to the subject matter of these Terms of Service,
                including liabilities arising in contract, in tort
                (including negligence) and for breach of statutory duty.
              </li>
            </ol>
            We will not be liable to you in respect of any losses arising out of
            any event or events beyond our reasonable control, including but not
            limited to industrial action, labor shortage, failure of our
            suppliers, natural disaster, transport disruption or failure,
            legislative or governmental intervention, or any other cause beyond
            our reasonable control.<br/>
            To the extent that you use our Products for business use we will not
            be liable to you in respect of any business losses, including
            (without limitation) loss of or damage to profits, income, revenue,
            use, production, anticipated savings, business, contracts,
            commercial opportunities or goodwill. We will not be liable for any
            commercial or human resources decisions made in connection with the
            use of our Products nor will we be liable for any liability in
            relation to employment disputes including but not limited to
            wrongful and unfair dismissal. We provide information and materials
            relating to personality traits. This information cannot provide and
            is not intended to provide comprehensive coverage of the relevant
            issues.<br/>
            Our content should always be used in conjunction with other
            information, advice and training.<br/>
            You should ensure that any decisions made or implemented by you
            after viewing our content or materials are made taking into account
            other factors apart from the information we provide of which you and
            your advisers should be aware including medical advice and you
            should ensure that you have consulted relevant professionals.<br/>
            We will not be liable to you in respect of any emotional or mental
            distress or other harm in connection with using our Products except
            to the extent that such damage is caused by our negligence.<br/>
            We will not be liable to you in respect of any loss or corruption of
            any data, database or software.<br/>
            We will not be liable to you in respect of any special, indirect or
            consequential loss or damage.<br/>
            Any decisions made or actions taken by you on the basis of
            information provided on or via the Website are at your sole
            discretion and risk. You should obtain professional advice where
            necessary. In the event that you have a dispute with any Team Member
            or other user arising from their use of the Website, you agree to
            pursue such claim or action independently of us, and you release us
            from all claims, liability and damages arising from any such
            dispute.<br/>
            In the event that notwithstanding the disclaimers and exclusions of
            liability in these terms we have any liability to you under or in
            relation to these terms and/or your use of the Website and/or any
            Products then our liability shall in any calendar year be limited in
            aggregate for all claims to the amount paid by you under these terms
            in that year, to the fullest extent such a limitation is permitted
            by law.
          </p>

          <Typography variant="h5">11. Indemnity and Further Exclusion of
            Liability</Typography>
          <p>You are responsible for how you use the Website and the use your
            Team
            Members make of the Website. To the fullest extent such exclusion is
            permitted by law we shall have no liability towards you and your
            Team
            Members in connection with or arising out of your or their use of
            the
            Website.
            You hereby indemnify us and undertake to keep us indemnified on a
            full
            indemnity basis against any losses, damages, costs, liabilities and
            expenses (including, without limitation, legal expenses and any
            amounts paid by us to a third party in settlement of a claim or
            dispute on the advice of our legal advisers) incurred or suffered by
            us arising out of or related to: any breach by you (or any of your
            Team Members) of any provision of these Terms of Service or the
            Member
            Terms (where applicable); arising out of any claim that you (or any
            of
            your Team Members) have breached any provision of these Terms of
            Service or the Member Terms (where applicable); any claims brought
            against us by Team Members relating to their use of the Website
            (except where such claims were caused by our fault); and the
            exercise
            by Team Members of any data subject rights against us under Chapter
            III of the General Data Protection Regulation (Regulation (EU)
            2016/679 and/or under any equivalent, similar or analogous laws
            which
            are applicable (“Data Privacy Laws”).</p>

          <Typography variant="h5">12. Breaches of These Terms of
            Service</Typography>
          <p>Without prejudice to our other rights under these Terms of Service,
            if you breach these Terms of Service in any way, we may take such
            action as we deem appropriate to deal with the breach, including
            suspending your access to the Website, prohibiting you from
            accessing the Website, blocking computers using your IP address from
            accessing the Website, contacting your internet service provider to
            request that they block your access to the Website and/or bringing
            court proceedings against you.</p>

          <Typography variant="h5">13. Assignment</Typography>
          <p>We may transfer, sub-contract or otherwise deal with our rights
            and/or obligations under these Terms of Service without notifying
            you or obtaining your consent. You may not transfer, sub-contract or
            otherwise deal with your rights and/or obligations under these Terms
            of Service.</p>

          <Typography variant="h5">14. Variation</Typography>
          <p>
            We may revise these Terms of Service from time to time. Revised
            Terms of Service will apply to the use of the Website from the date
            of publication of the revised Terms of Service on the Website.</p>

          <Typography variant="h5">15. Exclusion of Third Party
            Rights</Typography>
          <p>These Terms of Service are for the benefit of you and us, and are
            not intended to benefit any third party or be enforceable by any
            third party. The exercise of our and your rights in relation to
            these Terms of Service is not subject to the consent of any third
            party.</p>

          <Typography variant="h5">16. Severability</Typography>
          <p>If a provision of these Terms of Service is determined by any court
            or other competent authority to be unlawful and/or unenforceable,
            the other provisions will continue in effect. If any unlawful and/or
            unenforceable provision would be lawful or enforceable if part of it
            were deleted, that part will be deemed to be deleted, and the rest
            of the provision will continue in effect.</p>

          <Typography variant="h5">17. Entire Agreement</Typography>
          <p>
            These Terms of Service, together with our Privacy Notice, constitute
            the entire agreement between you and us in relation to your use of
            the Website and supersede all previous agreements in respect of your
            use of the Website and any previous oral or written understanding,
            commitments, contracts or representations relating to the subject
            matter of these terms. Each of us agrees that in entering into the
            contract of which these terms form part we have not relied on any
            representation, warranty, collateral contract or other assurance
            (except those, if any, expressly set out in these terms) made by or
            on behalf of the other. Each of us waives all rights and remedies
            which, but for this clause, might otherwise be available to us in
            respect of any such representation, warranty, collateral contract or
            other assurance. Nothing in this clause limits or excludes any
            liability or remedy for fraud or fraudulent misrepresentation.</p>

          <Typography variant="h5">18. Delay in Enforcing this
            Contract</Typography>
          <p>
            Even if we delay in enforcing this contract, we can still enforce it
            later. If we do not insist immediately that you do anything you are
            required to do under these terms, or if we delay in taking steps
            against you in respect of your breaking this contract, that will not
            mean that you do not have to do those things and it will not prevent
            us taking steps against you at a later date. For example, if you
            miss a payment and we do not chase you but we continue to provide
            the Products, we can still require you to make the payment at a
            later date.</p>

          <Typography variant="h5">19. Law and Jurisdiction</Typography>
          <p>
            These Terms of Service will be governed by and construed in
            accordance with German law, and any disputes relating to these Terms
            of Service will be subject to the exclusive jurisdiction of the
            courts Germany (Amtsgericht München). We may however take legal
            action to protect our intellectual property rights (IPR) and to
            enforce our right to be paid in any jurisdiction.</p>

          <Typography variant="h5">20. Data Protection</Typography>
          <p>You acknowledge that for the purposes of the Data Privacy Laws we
            are a data controller in relation to your personal data and that of
            any
            Team Members which you provide to us or which Team Members provide
            to us in the context of your use of the Website. You will be a data
            controller in relation to all outputs we provide to you (to the
            extent they are personal data). Both of us agree to comply with our
            obligations under the Data Privacy Laws and you confirm that you
            have the right to provide any personal data that you provide to us.
            Our
            Privacy Notice sets out how we process this personal data.</p>

          <Typography variant="h5">21. Our Details</Typography>
          <p>
            The full name of the company is MunichTrading UG
            (haftungsbeschränkt).<br/>
            Our registered address is Gundelindenstr. 5, D-80805 München<br/>
            Registration court: Amtsgericht München<br/>
            HRB 220190<br/>
            If you have any concerns or complaints you can contact us by email
            via <a href="mailto:hello@aligna.team">hello@aligna.team</a>.</p>
        </Grid>
      </Grid>
  );
};

export default Tos;
