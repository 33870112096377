import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Grid,
  Typography,
  Link,
  Paper,
} from '@material-ui/core';
import styles from './View.module.css';

const CharacterTypes = ({character_types}) => {
  // Transpose to list of people with certain character type
  let types = character_types.reduce(function(r, a) {
    r[a.character_type] = r[a.character_type] || [];
    r[a.character_type].push(a.name);
    return r;
  }, Object.create(null));

  let typeElements = [];
  Object.keys(types).forEach(function(key) {
    typeElements.push(
        <Grid item key={`character_type-${key}`}>
          <Box mr={2}>
            <Link
                target="_blank"
                rel="noopener"
                href={`https://www.16personalities.com/${key}-personality`}>
              <Typography component={'span'}>{key}</Typography>:&nbsp;
            </Link>
            {types[key].map((person, idx) => (
                <React.Fragment key={`character_type-${person}`}>
                  {idx > 0 && ', '}<span key={person.name}>{person}</span>
                </React.Fragment>))}
          </Box>
        </Grid>);
  });

  if (typeElements.length === 0) {
    return null;
  } else {
    return (
        <Paper square elevation={3}>
          <Box p={3} pt={1}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h6" className={styles.sectionHeading}>Character
                  Types</Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container direction="row"
                      justify="flex-start"
                      alignItems="flex-start"
                >
                  {typeElements}
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Paper>);
  }
};

CharacterTypes.propTypes = {
  character_types: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default CharacterTypes;