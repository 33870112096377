import React from 'react';
import PropTypes from 'prop-types';
import {
  Container,
} from '@material-ui/core';
/* UI Components */
import Footer from '../Footer';

const LandingPageLayout = (props) => {

  const {
    is_index,
    classes,
  } = props;

  return (
      <Container fixed>
        {/* Main */}
        <main className={is_index
            ? classes.content_index
            : classes.content}>
          <div className={classes.toolbar}/>
          <div className={classes.mainContainer}>
            {props.children}
          </div>
          {/* Footer */}
          <Footer/>
        </main>
      </Container>
  );
};

LandingPageLayout.propTypes = {
  children: PropTypes.node,
  is_index: PropTypes.bool,
  classes: PropTypes.object,
};

export default LandingPageLayout;