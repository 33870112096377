import ReactGA from "react-ga";
import {TRACKING_GA_ID} from '../constants';
import {store} from "../store";

const trackingEnabled = () => {
    return store.getState().analyticsReducer.tracking;
};

export const initGA = () => {
    ReactGA.initialize(TRACKING_GA_ID);
    ReactGA.ga('set', 'anonymizeIp', true);
};

export const PageView = () => {
    if(trackingEnabled()) {
        const location = window.location.pathname + window.location.search;
        ReactGA.pageview(location);
    }
};

export const Event = (category, action, label) => {
    if(trackingEnabled()) {
        ReactGA.event({
            category: category,
            action: action,
            label: label
        });
    }
};

export const disableTracking = () => {
    const disableStr = 'ga-disable-' + TRACKING_GA_ID;
    document.cookie = disableStr + '=true; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/';
    window[disableStr] = true;
};