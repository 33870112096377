import React from 'react';
import PropTypes from 'prop-types';
import {Typography, Grid, Box, Paper} from '@material-ui/core';
import { HashLink as Link } from 'react-router-hash-link';

import {
  SCORE_GOOD_LIMIT,
  NR_CRITICAL_QUESTIONS,
} from '../../constants';
import {colorCodeScore} from '../../utils/misc';
import styles from './View.module.css';

const CriticalQuestions = ({questions}) => {
  if (questions.size === 0) {
    return null;
  }

  const criticalQuestions = questions.filter(
      obj => obj.score < SCORE_GOOD_LIMIT);

  const questionElements = criticalQuestions.slice(0, NR_CRITICAL_QUESTIONS).
      map((question, i) => {
            return (
                <Grid item xs={12} key={i} className={styles.criticalQuestionItem}>
                  <Box style={{background: colorCodeScore(question.score)}}
                       className={styles.criticalQuestionsIndicator}/>
                  <Link smooth to={`#question-${question.alias}`} className={styles.criticalQuestionLink}><Typography>{question.heading}</Typography></Link>
                </Grid>);
          },
      );

  if (questionElements.length > 0) {
    return (
        <Paper square elevation={3}>
          <Box p={3} pt={1}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom className={styles.sectionHeading}>
                  Hot topics
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  {questionElements}
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Paper>);
  } else {return null;}
};

CriticalQuestions.propTypes = {
  questions: PropTypes.array.isRequired,
};

export default CriticalQuestions;