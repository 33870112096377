import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Paper,
  Grid,
  Typography,
} from '@material-ui/core';
import ScoreEmoji from './ScoreEmoji';
import styles from './View.module.css';

const CategoryOverview = ({categoryScores}) => {

  let categoryElements = [];
  categoryScores.forEach(function(category) {
    categoryElements.push(
        <Grid item xs={6} md={2} xl={1} key={category.name}>
          <Paper square>
            <ScoreEmoji score={category.score}/>
            <Box className={styles.categoryOverviewText}>
              <Typography variant="body2">{category.name}</Typography>
            </Box>
          </Paper>
        </Grid>,
    );
  });

  if (categoryElements.length === 0) {
    return null;
  } else {
    return (
        <Paper square elevation={3}>
          <Box p={3} pt={1}>
            <Grid container justify="flex-start">
              <Grid item xs={12}>
                <Typography variant="h6" className={styles.sectionHeading}>Category
                  results</Typography>
              </Grid>
              <Grid item xs={12} md={11} lg={8}>
                <Grid container direction="row"
                      alignItems="flex-start" spacing={2}>
                  {categoryElements}
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Paper>
    );
  }
};

CategoryOverview.propTypes = {
  categoryScores: PropTypes.array.isRequired,
};

export default CategoryOverview;