import React from 'react';
import TitleComponent from '../TitleComponent';
import {Grid, Typography} from '@material-ui/core/';
import styles from './Error.module.css';

const Thanks = () => {
  const imgNumber = Math.floor(Math.random() * 3);
  return (
      <Grid container>
        <TitleComponent title={'Thanks'}/>
        <Grid item xs={12}>
          <Typography component="h1" variant="h4" align="center">
            Thanks for supporting other teams, you are amazing!
          </Typography>
          <img className={styles.travolta} alt="Comic character saying sorry"
               src={process.env.PUBLIC_URL + `/imgs/dance/${imgNumber}.gif`}/>
        </Grid>
      </Grid>
  );
};

export default Thanks;
