import React from 'react';
import PropTypes from 'prop-types';
import Create from '../../components/Questionaire/Create';
import {
    apiQuestionnaireCreate,
    apiTeamGet,
} from '../../api/AlignaClient';
import * as Yup from 'yup';

class QuestionnaireCreateContainer extends React.Component {

    constructor(props) {
        super(props);
        this.QuestionnaireValidationSchema = Yup.object().shape({
            name: Yup.string()
                     .min(1, 'Too Short!')
                     .max(50, 'Too Long!')
                     .matches(/^[A-Za-z0-9 ]+$/,
                         {message: "Please only use letters, numbers and spaces"})
                     .required('Please enter your name'),
            email: Yup.string()
                      .email('Invalid email')
                      .required('Please enter your Email address'),
            accept_terms: Yup.boolean()
                             .test('accept_terms', 'Please confirm that you agree with our Terms and Conditions and Privacy Policy',
                                 value => value === true
                             )
        });
        this.state = {
            tabIndex: 0,
            teamHash: null,
        };
    }

    validateTeamOpen = () => {
        const {hash} = this.props.match.params;
        apiTeamGet(hash).then((response) => {
            if (response['closed'] === false) {
                this.setState({teamHash: hash});
            } else {
                this.props.history.push('/');
            }
        }).catch(() => {
            this.props.history.push('/');
        });
    };

    changeTab = (event, newValue) => {
        this.setState({tabIndex: newValue});
    };

    handleNext = () => {
        window.scrollTo(0, 0);
        this.setState(state => ({tabIndex: state.tabIndex + 1}));
    };

    handleBack = () => {
        window.scrollTo(0, 0);
        this.setState(state => ({tabIndex: state.tabIndex - 1}));
    };

    handleSubmit = (values, actions) => {
        const hash = this.state.teamHash;

        apiQuestionnaireCreate(hash, values).then(() => {
            // Build route
            let path = '/team/' + hash + '/view';
            // Redirect
            this.props.history.push(path);
        }).catch(() => {
            actions.setStatus({msg: 'Something went wrong'});
        }).finally(() =>
            actions.setSubmitting(false),
        );
    };

    componentDidMount() {
        this.validateTeamOpen();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const {hash} = nextProps.match.params;
        if (hash !== prevState.teamHash && hash !== null) {
            return {teamHash: hash};
        } else return null;
    }

    render() {
        return (
            <Create
                teamHash={this.state.hash}
                handleSubmitFun={this.handleSubmit}
                changeTabFun={this.changeTab}
                handleNextFun={this.handleNext}
                handleBackFun={this.handleBack}
                validationSchema={this.QuestionnaireValidationSchema}
                value={this.state.tabIndex}
            />
        );
    }
}

QuestionnaireCreateContainer.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            hash: PropTypes.string.isRequired,
        }),
    }).isRequired,
    history: PropTypes.object.isRequired,
};

export default QuestionnaireCreateContainer;