import React from 'react';
import TitleComponent from '../TitleComponent';

class About extends React.Component {
  render() {
    return (
        <React.Fragment>
          <TitleComponent title={'About'}/>
        </React.Fragment>
    );
  }
}

export default About;