import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Grid,
  Typography,
} from '@material-ui/core';
import AnswerMap from './AnswerMap';
import styles from './View.module.css';
import {colorCodeScore} from '../../utils/misc';

const DetailsQuestion = ({question, voters}) => {
  return (
      <Grid item xs={12} className={styles.criticalQuestionItem} key={question.alias} id={`question-${question.alias}`}>
        <Box style={{background: colorCodeScore(question.score), flex: '0 0 auto', height: '100%', width: '2px', marginRight:'15px'}}/>
        <Grid container direction="column">
          <Grid item style={{maxWidth: '700px'}}>
            <Typography color="primary">{question.heading}</Typography>
          </Grid>
          <Grid item>
            <AnswerMap distribution={question.distribution} voters={voters}/>
          </Grid>
        </Grid>
      </Grid>
  );
};

DetailsQuestion.propTypes = {
  question: PropTypes.object.isRequired,
  voters: PropTypes.array.isRequired
};

export default DetailsQuestion;