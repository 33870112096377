import {
  API_URL,
  API_ROUTE_TEAMS,
  API_VERSION,
  API_ROUTE_QUESTIONNAIRES,
  API_ROUTE_ADVICE,
} from '../constants/AlignaAPI';
import axios from 'axios';

export async function apiTeamGet(hash) {
  const url = API_URL + API_VERSION + API_ROUTE_TEAMS + '/' + hash;
  const response = await axios.get(url);
  return response.data;
}

export async function apiQuestionnairesGet(hash) {
  const url = API_URL + API_VERSION + API_ROUTE_TEAMS + '/' + hash +
      '/questionnaires';
  const response = await axios.get(url);
  return response.data;
}

export async function apiAnalysisGet(hash) {
  const url = API_URL + API_VERSION + API_ROUTE_TEAMS + '/' + hash +
      '/analysis';
  const response = await axios.get(url);
  return response.data;
}

export async function apiTeamCreate(survey_data) {
  const url = API_URL + API_VERSION + API_ROUTE_TEAMS;
  const response = await axios.post(url, survey_data);
  return response.data;
}

export async function apiTeamClose(hash) {
  const url = API_URL + API_VERSION + API_ROUTE_TEAMS + '/' + hash + '/close';
  const response = await axios.put(url);
  return response.data;
}

export async function apiTeamDelete(hash, redirect) {
  const url = API_URL + API_VERSION + API_ROUTE_TEAMS + '/' + hash;
  const response = await axios.delete(url);
  if (redirect === true) {
    this.props.history.replace('/');
  }
  return response.data;
}

export async function apiQuestionnaireCreate(hash, data) {
  const url = API_URL + API_VERSION + API_ROUTE_TEAMS + '/' + hash +
      '/questionnaires';
  const response = await axios.post(url, data);
  return response.data;
}

export async function apiQuestionnaireDelete(hash) {
  const url = API_URL + API_VERSION + API_ROUTE_QUESTIONNAIRES + '/' + hash;
  const response = await axios.delete(url);
  return response.data;
}

export async function apiAdviceOptout(user_hash) {
  const url = API_URL + API_VERSION + API_ROUTE_ADVICE + '/' + user_hash +
      '/optout';
  const response = await axios.post(url);
  return response.data;
}

export async function apiAdviceCreate(user_hash, team_hash, question_alias, data) {
  const url = API_URL + API_VERSION + API_ROUTE_ADVICE + '/' + user_hash +
      '/'+ team_hash +'/'+ question_alias;
  console.log(data);
  const response = await axios.post(url, data);
  return response.data;
}

export async function apiAdviceDelete(user_hash, advice_hash) {
  const url = API_URL + API_VERSION + API_ROUTE_ADVICE + '/' + user_hash +
      '/'+ advice_hash;
  const response = await axios.delete(url);
  return response.data;
}

export async function apiAdviceGetOwn(user_hash) {
  const url = API_URL + API_VERSION + API_ROUTE_ADVICE + '/' + user_hash;
  const response = await axios.get(url);
  return response.data;
}

export async function apiAdviceGet(question_alias) {
  const url = API_URL + API_VERSION + API_ROUTE_ADVICE + '/all/' + question_alias;
  const response = await axios.get(url);
  return response.data;
}