import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Route, withRouter} from 'react-router-dom';

import DefaultLayoutStyled from '../../components/Layout/Default';

class DefaultLayout extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      drawerOpen: false,
      teamsOpen: true,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged = () => {
    this.setState({drawerOpen: false});
  };

  handleTitleTap() {
    this.props.history.push('/');
  }

  handleDrawerChange(status) {
    this.setState({drawerOpen: status});
  }

  drawerToggleFun = () => {
    this.setState({drawerOpen: !this.state.drawerOpen});
  };

  handleTeamsToggle = () => {
    this.setState({teamsOpen: !this.state.teamsOpen});
  };

  render() {
    return (
        <DefaultLayoutStyled
            is_index={this.props.is_index}
            drawerToggleFun={this.drawerToggleFun}
            handleDrawerChange={this.handleDrawerChange}
            drawerState={this.state.drawerOpen}
            teamsToggleFun={this.handleTeamsToggle}
            teamsExpandedState={this.state.teamsOpen}
            onTitleTouchTap={this.handleTitleTap}
        >{this.props.children}
        </DefaultLayoutStyled>
    );
  }
}

DefaultLayout.propTypes = {
  is_index: PropTypes.bool,
  children: PropTypes.element,
  location: PropTypes.object,
  history: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {teams: state.teamReducer, user: state.userReducer};
};

const DefaultLayoutConnected = connect(mapStateToProps)(withRouter(DefaultLayout));

const DefaultLayoutRoute = ({component: Component, ...rest}) => {
  return (
      <Route {...rest} render={routeProps => (
          <DefaultLayoutConnected is_index={rest.is_index}>
            <Component {...routeProps}/>
          </DefaultLayoutConnected>
      )}/>
  );
};

DefaultLayoutRoute.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.element,
    PropTypes.any,
  ]),
};

export default DefaultLayoutRoute;