import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import {CheckBoxOutlineBlank, CheckBox} from '@material-ui/icons';
import styles from './View.module.css';

const AnswerMap = ({distribution, voters}) => {

  let rows = [];
  for (let [idx, data] of Object.entries(distribution)) {
    let columns = [];
    voters.forEach((voter, idx) => {
      const included = data.voters.includes(voter);
      columns.push(
          <TableCell key={`distribution-${idx}`}
                     align="center" className={styles.questionDetailsGridCell}>
            {included ? <CheckBox/> : <CheckBoxOutlineBlank/>}
          </TableCell>,
      );
    });
    rows.push(
        <TableRow key={`distribution-row-${idx}`}>
          <TableCell className={styles.questionDetailsGridCell}>
            {data.label}
          </TableCell>
          {columns}
        </TableRow>);
  }

  return (
      <div>
        <Grid container>
          <Grid item xs={12}>
            <TableContainer>
              <Table size="small" aria-label="a dense table"
                     style={{width: 'auto', tableLayout: 'auto'}}>
                <TableHead>
                  <TableRow>
                    <TableCell style={{width: '80%'}}></TableCell>
                    {voters.map((voter) => {
                      return (<TableCell key={`voter-${voter}`}
                                         align="center">{voter}</TableCell>);
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Divider/>
        </Grid>
      </div>
  );
};

AnswerMap.propTypes = {
  distribution: PropTypes.object.isRequired,
  voters: PropTypes.array.isRequired,
};

export default AnswerMap;