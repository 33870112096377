import React from 'react';
import {Link} from 'react-router-dom';
import {Typography, Grid} from '@material-ui/core';
import {PAGE_NAME, PAGE_URL} from '../../constants';
import styles from './Footer.module.css';

const Copyright = () => {
  return (
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <a color="inherit" href={PAGE_URL}>{PAGE_NAME}</a>
        {' '}
        {new Date().getFullYear()}
      </Typography>
  );
};

const Footer = () => {
  return (
      <footer className={styles.footer}>
        <Grid item className={styles.footer_nav}>
          <ul className={styles.footer_nav_list}>
            <li><Link to="/legal">Legal Notice</Link></li>
            <li><Link to="/privacy">Privacy</Link></li>
            <li><Link to="/tos">Terms of Service</Link></li>
          </ul>
          <Copyright/>
        </Grid>
      </footer>
  );
};

export default Footer;