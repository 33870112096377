import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Switch from '@material-ui/core/Switch';
import {setTracking as setTrackingAction} from '../../../store/actions/Analytics';

class TrackingToggle extends React.Component {

  setTracking = (event) => {
    this.props.setTrackingAction(event.currentTarget.checked);
  };

  render() {
    return (
        <div>
          <Switch
              checked={this.props.trackingEnabled}
              onChange={this.setTracking}
              value="checkedA"
              inputProps={{'aria-label': 'secondary checkbox'}}
          />
        </div>
    );
  }
}

TrackingToggle.propTypes = {
  setTrackingAction: PropTypes.func.isRequired,
  trackingEnabled: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    trackingEnabled: state.analyticsReducer.tracking,
  };
};

const mapDispatchToProps = dispatch => ({
  setTrackingAction: value => dispatch(setTrackingAction(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TrackingToggle);