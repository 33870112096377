/* API */
export const API_VERSION = 'v1';
const { REACT_APP_API_URL } = process.env;

export const API_URL = REACT_APP_API_URL;
//export const API_URL = 'https://api.aws.aligna.team/';
//export const API_URL = 'http://localhost:8080/';
export const API_ROUTE_TEAMS = '/teams';
export const API_ROUTE_QUESTIONNAIRES = '/questionnaires';

//advice
export const API_ROUTE_ADVICE = '/advice';