import React from 'react';
import PropTypes from 'prop-types';
import {
  Paper,
  Box,
  Grid,
  Typography,
} from '@material-ui/core';
import DetailsQuestion from './DetailsQuestion';
import styles from './View.module.css';

const Details = ({categories, voters}) => {

  let categoryElements = [];

  for (const key in categories) {
    let categoryName = key.split("_").join(' ');

    categoryElements.push(
        <Grid item key={`all_questions_${key}`} xs={12}>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h6"
                  className={styles.categoryDetailsHeading}>{categoryName}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={6}>
                {
                  categories[key].questions.map((question) => {
                    return (
                        <DetailsQuestion key={`${question.alias}-question`}
                                         question={question}
                                         category={key}
                                         voters={voters}
                        />
                    );
                  })
                }
              </Grid>
            </Grid>
          </Grid>
        </Grid>,
    );
  }

  if (categoryElements.length === 0) {
    return null;
  } else {
    return (
        <Paper>
          <Box p={3} pt={1}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h6" className={styles.sectionHeading}>
                  All questions
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {categoryElements}
              </Grid>
            </Grid>
          </Box>
        </Paper>
    );
  }
};

Details.propTypes = {
  categories: PropTypes.object.isRequired,
  voters: PropTypes.array.isRequired
};

export default Details;