import React from 'react';
import PropTypes from 'prop-types';
import {Tab, Tabs} from '@material-ui/core';
import {
  PersonPinRounded as PersonalityIcon,
  Whatshot as HotIcon,
  AttachMoney as MoneyIcon,
  FilterHdr as CommitmentIcon,
  GroupRounded as TeamWorkIcon,
  Send as SendIcon
} from '@material-ui/icons';

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const TabsMenu = (props) => {

  const {value, changeTabFun} = props;

  return (
      <Tabs
          value={value}
          onChange={changeTabFun}
          variant="scrollable"
          scrollButtons="auto"
          indicatorColor="primary"
          textColor="primary"
          aria-label="scrollable auto tabs"
      >
        <Tab label="Personality" icon={<PersonalityIcon/>} {...a11yProps(0)} />
        <Tab label="Motivations" icon={<HotIcon/>} {...a11yProps(1)} />
        <Tab label="Time and Money" icon={<MoneyIcon/>} {...a11yProps(2)} />
        <Tab label="Commitment" icon={<CommitmentIcon/>} {...a11yProps(3)} />
        <Tab label="Team Work" icon={<TeamWorkIcon/>} {...a11yProps(4)} />
        <Tab label="Submit" icon={<SendIcon/>} {...a11yProps(5)} />
      </Tabs>
  );
};

TabsMenu.propTypes = {
  value: PropTypes.number,
  changeTabFun: PropTypes.func.isRequired,
};

export default TabsMenu;