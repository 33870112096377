import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Grid,
  FormHelperText,
  FormControlLabel,
  Typography, LinearProgress,
} from '@material-ui/core';
import {TextField, CheckboxWithLabel} from 'formik-material-ui';
import ActionButton from '../ActionButton';
import {FastField, Form, Formik} from 'formik';

export const Create = (props) => {
  const {
    handleSubmitFun,
    validationSchema,
    team,
    questionData,
  } = props;

  const CreateAdviceForm = ({
    handleSubmit,
    setSubmitting,
    handleChange,
    handleBlur,
    errors,
    touched,
    isSubmitting,
    status,
    submitForm,
    setFieldValue,
  }) => (
      <React.Fragment>
        <Form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography component="p" variant="h5" gutterBottom>Your Name</Typography>
              <Typography component="p" variant="body2" color="secondary">
                This field is optional and will be displayed to other users.
              </Typography>
              <FastField
                  name="name"
                  component={TextField}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" gutterBottom>Is your team still active</Typography>
              <FormControlLabel style={{marginLeft:'0px'}}
                  name="team_active"
                  control={
                    <FastField component={CheckboxWithLabel}/>
                  }
              />
              <FormHelperText><Typography color="error" component="span">{
                errors.team_active &&
                touched.team_active &&
                String(errors.team_active)}</Typography>
              </FormHelperText>
            </Grid>
            <Grid item xs={12}>
              <Typography variant={'h5'} gutterBottom>Your advice *</Typography>
              <FastField
                  name="advice"
                  required
                  multiline
                  fullWidth
                  rows={6}
                  inputProps={{maxLength: 1200}}
                  component={TextField}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container direction="column"
                    justify="flex-start"
                    alignItems="center">
                <Grid item xs={8} lg={6}>
                  {status && status.msg ? (
                      <Typography color='error'>API
                        Error: {status.msg}</Typography>
                  ) : null}
                </Grid>
                <Grid item xs={12}>
                  <ActionButton
                      variant="contained"
                      color="primary"
                      type="submit"
                      loading={isSubmitting}
                      disabled={
                        isSubmitting ||
                        Object.keys(errors).length !== 0 ||
                        Object.keys(touched).length === 0
                      }
                  >
                    Submit advice
                  </ActionButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      </React.Fragment>
  );

  CreateAdviceForm.propTypes = {
    handleSubmit: PropTypes.func,
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
    values: PropTypes.object,
    errors: PropTypes.object,
    status: PropTypes.object,
    touched: PropTypes.object,
    isSubmitting: PropTypes.bool,
  };

  if (team == null) {
    return <LinearProgress/>;
  } else {
    return (
        <Grid container spacing={3}>
          <Grid item xs="12" md={12} lg={8}>
            <Typography variant="h5" component="h1" gutterBottom>Advice</Typography>
            <Box mt="3">
              Your team <Typography component="span" color="primary">
              {team ? team.alias : null}
            </Typography> has recently used Aligna.Team to figure out if you agree
              on important topics.<br/>
              It seems as if your team had different opinions concerning the question:&nbsp;
              <Typography component="span" color="primary">
                {questionData ? questionData.heading : null}
              </Typography><br/>
              Please help other teams that have issues with the same topic
              by giving them your valuable advice based on previous experience.
            </Box>
          </Grid>
          <Grid item xs={12}>
            <div>
              <Grid container
                    direction="column"
                    justify="flex-start"
                    alignItems="flex-start"
                    spacing={3}>
                <Grid item xs={12}>
                  <Formik
                      initialValues={{
                        name: '',
                        advice: '',
                        team_active: true
                      }}
                      onSubmit={handleSubmitFun}
                      validationSchema={validationSchema}
                      isInitialValid={false}
                      component={CreateAdviceForm}
                  />
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>);
  }
  ;
};

Create.propTypes = {
  handleSubmitFun: PropTypes.func.isRequired,
  validationSchema: PropTypes.object,
  team: PropTypes.object.isRequired,
  questionData: PropTypes.object.isRequired,
};

export default Create;