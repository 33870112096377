import React from 'react';
import {Typography, Grid} from '@material-ui/core';
import TitleComponent from '../TitleComponent';
import styles from './Error.module.css';

class NotFound extends React.Component {
  render() {
    return (
        <Grid container>
          <TitleComponent title={'Not Found'}/>
          <Grid item xs={12}>
            <Typography component="h1" variant="h4" align="center">
              Not Found
            </Typography>
            <img className={styles.travolta} alt="John Travolta looking around"
                 src={process.env.PUBLIC_URL + '/imgs/404.gif'}/>
          </Grid>
        </Grid>
    );
  }
}

export default NotFound;