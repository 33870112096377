/* General */
export const PAGE_NAME = 'Aligna.Team';
export const PAGE_URL = 'https://aligna.team/';

/* Donations */
export const DONATIONS_PAYPAL_RECIPIENT = 'donations@aligna.team';
export const DONATIONS_PAYPAL_TEXT = 'Support '+PAGE_NAME;

/* Tracking */
export const TRACKING_GA_ID = 'UA-145903756-1';

/* Google reCaptcha */
export const RECAPTCHA_SITE_KEY = '6LcNjsIUAAAAAIKveBYoIXrZ2gS4opRWgljHy7ym';

export const SCORE_BAD_LIMIT = 0.5;
export const SCORE_GOOD_LIMIT = 0.7;

export const NR_CRITICAL_QUESTIONS = 8;