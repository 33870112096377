import {createMuiTheme} from '@material-ui/core/styles';
import {red, deepPurple} from '@material-ui/core/colors';

let theme = createMuiTheme({
  typography: {
    // Account for base font-size of 62.5%.
    htmlFontSize: 16,
  },

  palette: {
    primary: deepPurple,
    secondary: {
      main: '#ffa700',
      contrastText: '#fff',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fff',
    },
    appBar: {
      backgroundColor: '#fff',
    },
  },
  overrides: {
    MuiTableRow: {
      root: {
        '& td': {
          borderBottom: 0,
        },
      },
    },
  },
});

//theme = responsiveFontSizes(theme, {factor:3});

export default theme;
