import React from 'react';
import PropTypes from 'prop-types';
import {LinearProgress} from '@material-ui/core';
import {apiAdviceOptout} from '../../api/AlignaClient';

class AdviceOptoutContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  optout = async (user_hash) => {
    this.setState({
      ...this.state,
      'loading': true,
    });
    apiAdviceOptout(user_hash).then(() => {
      this.props.history.push('');
    }).catch(() => {
      this.props.history.push('/oops');
    }).finally(() => {
      this.setState({
        ...this.state,
        'loading': false,
      });
    });
  };

  componentDidMount() {
    const {user_hash} = this.props.match.params;
    this.optout(user_hash);
  }

  render() {
    if (this.state.loading === true) {
      return <LinearProgress/>;
    } else {
      return null;
    }
  }
}

AdviceOptoutContainer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      user_hash: PropTypes.string.isRequired,
    }),
  }).isRequired,
  history: PropTypes.object.isRequired,
};

export default AdviceOptoutContainer;