import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Create from '../../components/Team/Create';
import {addTeam} from '../../store/actions/Teams';
import {apiTeamCreate} from '../../api/AlignaClient';
import * as Yup from 'yup';

class TeamCreateContainer extends React.Component {

    constructor(props) {
        super(props);

        this._reCaptchaRef = React.createRef();
    }

    TeamValidationSchema = Yup.object().shape({
        alias: Yup.string()
                  .min(1, 'Too Short!')
                  .max(20, 'Too Long!')
                  .required('Please enter an alias for your team')
                  .matches(/^[A-Za-z0-9 ]+$/, {message: 'Please only use letters, numbers and spaces'}),
        email: Yup.string().email('Invalid email').required('Please enter your Email address'),
        accept_terms: Yup.boolean().test(
            'accept_terms',
            'Please confirm that you agree with our Terms and Conditions and Privacy Policy',
            value => value === true,
        ),
    });

    handleSubmit = async (values, actions) => {
        if (values.recaptcha) {
            apiTeamCreate(values).then((result) => {
                this.props.addTeam(result);
                // Build route
                let path = '/team/' + result.hash + '/view';
                // Redirect
                this.props.history.push(path);
                this._reCaptchaRef.current.reset();
            }).catch(() => {
                actions.setStatus({msg: 'Something went wrong'});
            }).finally(() =>
                actions.setSubmitting(false),
            );
        } else {
            this._reCaptchaRef.current.execute();
            actions.setSubmitting(false);
        }
    };

    render() {
        return (
            <Create
                handleSubmitFun={this.handleSubmit}
                validationSchema={this.TeamValidationSchema}
                reCaptchaRef={this._reCaptchaRef}
            />
        );
    }
}

TeamCreateContainer.propTypes = {
    addTeam: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {teams: state.teamReducer};
};

const mapDispatchToProps = dispatch => {
    return {
        addTeam: payload => dispatch(addTeam(payload)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(TeamCreateContainer);