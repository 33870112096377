import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

const DialogDelete = (props) => {
  const {
    deleteTeamFun,
    confirmDeletionState,
    closeDeletionFun,
  } = props;

  return (
      <Dialog open={confirmDeletionState} onClose={closeDeletionFun}
              aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Confirm deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure that you want to delete this team?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeletionFun} color="secondary">
            Cancel
          </Button>
          <Button onClick={deleteTeamFun} color="primary">
            Continue
          </Button>
        </DialogActions>
      </Dialog>
  );
};

DialogDelete.propTypes = {
  deleteTeamFun: PropTypes.func.isRequired,
  confirmDeletionState: PropTypes.bool,
  closeDeletionFun: PropTypes.func.isRequired,
};

export default DialogDelete;