import React from 'react';
import TitleComponent from '../TitleComponent';
import {Grid, Typography} from '@material-ui/core/';
import TrackingToggle from './TrackingToggle';

const Privacy = () => {
  return (
      <Grid container direction="column" justify="flex-start"
            alignItems="flex-start" style={{textAlign: 'justify'}}>
        <TitleComponent title={'Privacy'}/>
        <Grid item xs={12} md={6}>
          <Typography variant="h4" gutterBottom>Tracking</Typography>
          <p>We use Google Analytics to be able to analyze how people use our
            site, but you can simply disable this by deactivating tracking:
          </p>
          <TrackingToggle/>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h4" gutterBottom component="h1">Privacy
            Policy</Typography>
          <Typography variant="h4" gutterBottom>1. An overview of data
            protection</Typography>
          <Typography variant="h6" gutterBottom>General information</Typography>
          <p>The
            following information will provide you with an easy to navigate
            overview
            of what will happen with your personal data when you visit our
            website.
            The term “personal data” comprises
            all data that can be used to personally identify you. For detailed
            information about the subject matter of
            data protection, please consult our Data Protection Declaration,
            which
            we have included beneath this
            copy.</p>
          <Typography variant="h6" gutterBottom>Data recording on our
            website</Typography> <p>
          <strong>Who is the responsible party for the recording of data on
            this website (i.e. the “controller”)?</strong></p> <p>The data on
          this
          website is processed by the operator
          of the website, whose contact information is available under section
          “Information Required by Law” on this
          website.</p> <p><strong>How do we record your data?</strong></p> <p>We
          collect your data as a result of your
          sharing of your data with us. This may, for instance be information
          you
          enter into our contact form.</p>
          <p>Our IT systems automatically record other data when you visit our
            website. This data comprises primarily
            technical information (e.g. web browser, operating system or time
            the
            site was accessed). This
            information is recorded automatically when you access our
            website.</p>
          <p><strong>What are the purposes
            we use your data for?</strong></p> <p>A portion of the information
          is
          generated to guarantee the error free
          provision of the website. Other data may be used to analyse your user
          patterns.</p> <p><strong>What rights
          do you have as far as your information is concerned?</strong></p>
          <p>You
            have the right to receive
            information about the source, recipients and purposes of your
            archived
            personal data at any time without
            having to pay a fee for such disclosures. You also have the right to
            demand that your data are rectified,
            blocked or eradicated. Please do not hesitate to contact us at any
            time
            under the address disclosed in
            section “Information Required by Law” on this website if you have
            questions about this or any other data
            protection related issues. You also have the right to log a
            complaint
            with the competent supervising
            agency.</p> <p>Moreover, under certain circumstances, you have the
          right
          to demand the restriction of the
          processing of your personal data. For details, please consult the Data
          Protection Declaration under section
          “Right to Restriction of Data Processing.”</p>
          <Typography variant="h6" gutterBottom>Analysis tools and tools
            provided by third
            parties</Typography> <p>There is a possibility that your browsing
          patterns will be statistically analysed when your visit our website.
          Such analyses are performed primarily
          with cookies and with what we refer to as analysis programmes. As a
          rule, the analyses of your browsing
          patterns are conducted anonymously; i.e. the browsing patterns cannot
          be
          traced back to you. You have the
          option to object to such analyses or you can prevent their performance
          by not using certain tools. For
          detailed information about this, please consult our Data Protection
          Declaration below.</p> <p>You do have
          the option to object to such analyses. We will brief you on the
          objection options in this Data Protection
          Declaration.</p>
          <Typography variant="h4" gutterBottom>2. General information and
            mandatory
            information</Typography>
          <Typography variant="h6" gutterBottom>Data protection</Typography>
          <p>The operators
            of this website and its pages take the protection of your personal
            data very seriously. Hence, we handle your personal data as
            confidential
            information and in compliance with
            the statutory data protection regulations and this Data Protection
            Declaration.</p> <p>Whenever you use this
          website, a variety of personal information will be collected. Personal
          data comprises data that can be used
          to personally identify you. This Data Protection Declaration explains
          which data we collect as well as the
          purposes we use this data for. It also explains how, and for which
          purpose the information is collected.</p>
          <p>We herewith advise you that the transmission of data via the
            Internet
            (i.e. through e-mail
            communications) may be prone to security gaps. It is not possible to
            completely protect data against
            third party access.</p>
          <Typography variant="h6" gutterBottom>Information about the
            responsible party
            (referred to as the “controller” in the GDPR)</Typography> <p>The
          data
          processing controller on this website is:</p> <p>Munich Trading UG
          (haftungsbeschränkt)<br/>
          Gundelindenstr. 5<br/>
          D-80805 München</p>

          <p>Phone: +49 (0)89 381 537 90<br/>
            E-mail: legal@munichtrading.com</p>
          <p>The controller is the natural person or legal entity that
            single-handedly or jointly with others makes
            decisions as to the purposes of and resources for the processing of
            personal data (e.g. names, e-mail addresses, etc.).</p>
          <Typography variant="h6" gutterBottom>Revocation of your consent to
            the processing of
            data</Typography> <p>A wide range of data processing
          transactions are possible only subject to your express consent. You
          can
          also revoke at any time any consent
          you have already given us. To do so, all you are required to do is
          sent
          us an informal notification via
          e-mail. This shall be without prejudice to the lawfulness of any data
          collection that occurred prior to your
          revocation.</p>
          <Typography variant="h6" gutterBottom>Right to object to the
            collection of data in
            special cases; right to object to direct advertising (Art.
            21 GDPR)</Typography> <p><strong>In the event that data are
          processed
          on the basis of Art. 6 Sect. 1 lit. e or f
          GDPR, you have the right to at any time object to the processing of
          your
          personal data based on grounds
          arising from your unique situation. This also applies to any profiling
          based on these provisions. To
          determine the legal basis, on which any processing of data is based,
          please consult this Data Protection
          Declaration. If you log an objection, we will no longer process your
          affected personal data, unless we are
          in a position to present compelling protection worthy grounds for the
          processing of your data, that outweigh
          your interests, rights and freedoms or if the purpose of the
          processing
          is the claiming, exercising or
          defence of legal entitlements (objection pursuant to Art. 21 Sect. 1
          GDPR).</strong></p> <p><strong>If your
          personal data is being processed in order to engage in direct
          advertising, you have the right to at any time
          object to the processing of your affected personal data for the
          purposes
          of such advertising. This also
          applies to profiling to the extent that it is affiliated with such
          direct advertising. If you object, your
          personal data will subsequently no longer be used for direct
          advertising
          purposes (objection pursuant to
          Art. 21 Sect. 2 GDPR).</strong></p>
          <Typography variant="h6" gutterBottom>Right to log a complaint with
            the competent
            supervisory agency</Typography> <p>In the event of violations of the
          GDPR, data subjects are entitled to log a complaint with a supervisory
          agency, in particular in the member
          state where they usually maintain their domicile, place of work or at
          the place where the alleged violation
          occurred. The right to log a complaint is in effect regardless of any
          other administrative or court
          proceedings available as legal recourses.</p>
          <Typography variant="h6" gutterBottom>Right to data
            portability</Typography> <p>You
          have the right to demand that we hand over any data we
          automatically process on the basis of your consent or in order to
          fulfil
          a contract be handed over to you or
          a third party in a commonly used, machine readable format. If you
          should
          demand the direct transfer of the
          data to another controller, this will be done only if it is
          technically
          feasible.</p>
          <Typography variant="h6" gutterBottom>SSL and/or TLS
            encryption</Typography> <p>For
          security reasons and to protect the transmission of confidential
          content, such as purchase orders or inquiries you submit to us as the
          website operator, this website uses
          either an SSL or a TLS encryption programme. You can recognise an
          encrypted connection by checking whether
          the address line of the browser switches from “http://” to “https://”
          and also by the appearance of the lock
          icon in the browser line.</p> <p>If the SSL or TLS encryption is
          activated, data you transmit to us cannot
          be read by third parties.</p>
          <Typography variant="h6" gutterBottom>Encrypted payment transactions
            on this
            website</Typography> <p>If you are under an obligation to share your
          payment information (e.g. account number if you give us the authority
          to
          debit your bank account) with us
          after you have entered into a fee-based contract with us, this
          information is required to process
          payments.</p> <p>Payment transactions using common modes of paying
          (Visa/MasterCard, debit to your bank
          account) are processed exclusively via encrypted SSL or TLS
          connections.
          You can recognise an encrypted
          connection by checking whether the address line of the browser
          switches
          from “http://” to “https://” and
          also by the appearance of the lock icon in the browser line.</p> <p>If
          the communication with us is
          encrypted, third parties will not be able to read the payment
          information you share with us.</p>
          <Typography variant="h6" gutterBottom>Information about, blockage,
            rectification and
            eradication of data</Typography> <p>Within the scope of the
          applicable statutory provisions, you have the right to at any time
          demand information about your archived
          personal data, their source and recipients as well as the purpose of
          the
          processing of your data. You may
          also have a right to have your data rectified, blocked or eradicated.
          If
          you have questions about this
          subject matter or any other questions about personal data, please do
          not
          hesitate to contact us at any time
          at the address provided in section “Information Required by Law.”</p>
          <Typography variant="h6" gutterBottom>Right to demand processing
            restrictions</Typography> <p>You have the right to demand the
          imposition of
          restrictions as far as the processing of your personal data is
          concerned. To do so, you may contact us at
          any time at the address provided in section “Information Required by
          Law.” The right to demand restriction
          of processing applies in the following cases:</p>
          <ul>
            <li>In the event that you should dispute the correctness of your
              data
              archived by us, we will usually
              need some time to verify this claim. During the time that this
              investigation is ongoing, you have
              the right to demand that we restrict the processing of your
              personal
              data.
            </li>
            <li>If the processing of your personal data was/is conducted in an
              unlawful manner, you have the option
              to demand the restriction of the processing of your data in lieu
              of
              demanding the eradication of
              this data.
            </li>
            <li>If we do not need your personal data any longer and you need it
              to
              exercise, defend or claim legal
              entitlements, you have the right to demand the restriction of the
              processing of your personal data
              instead of its eradication.
            </li>
            <li>If you have raised an objection pursuant to Art. 21 Sect. 1
              GDPR,
              your rights and our rights will
              have to be weighed against each other. As long as it has not been
              determined whose interests
              prevail, you have the right to demand a restriction of the
              processing of your personal data.
            </li>
          </ul>
          <p>If you have restricted the processing of your personal data, these
            data – with the exception of their
            archiving – may be processed only subject to your consent or to
            claim,
            exercise or defend legal
            entitlements or to protect the rights of other natural persons or
            legal entities or for important public
            interest reasons cited by the European Union or a member state of
            the
            EU.</p>
          <Typography variant="h6" gutterBottom>Rejection of unsolicited
            e-mails</Typography>
          <p>We herewith object to the use of contact information published
            in conjunction with the mandatory information to be provided in
            section “Information Required by Law” to
            send us promotional and information material that we have not
            expressly requested. The operators of this
            website and its pages reserve the express right to take legal action
            in the event of the unsolicited sending
            of promotional information, for instance via SPAM messages.</p>

          <Typography variant="h4" gutterBottom>3. Recording of data on our
            website</Typography>
          <Typography variant="h6" gutterBottom>Cookies</Typography> <p>In some
          instances, our
          website and its pages use so-called cookies. Cookies do not cause
          any damage to your computer and do not contain viruses. The purpose of
          cookies is to make our website more
          user friendly, effective and more secure. Cookies are small text files
          that are placed on your computer and
          stored by your browser.</p> <p>Most of the cookies we use are
          so-called
          “session cookies.” They are
          automatically deleted after your leave our site. Other cookies will
          remain archived on your device until you
          delete them. These cookies enable us to recognise your browser the
          next
          time you visit our website.</p>
          <p>You can adjust the settings of your browser to make sure that you
            are
            notified every time cookies are
            placed and to enable you to accept cookies only in specific cases or
            to exclude the acceptance of
            cookies for specific situations or in general and to activate the
            automatic deletion of cookies when you
            close your browser. If you deactivate cookies, the functions of this
            website may be limited.</p>
          <p>Cookies that are required for the performance of the electronic
            communications transaction or to provide
            certain functions you want to use (e.g. the shopping cart function),
            are stored on the basis of Art. 6
            Sect. 1 lit. f GDPR. The website operator has a legitimate interest
            in
            storing cookies to ensure the
            technically error free and optimised provision of the operator’s
            services. If other cookies (e.g.
            cookies for the analysis of your browsing patterns) should be
            stored,
            they are addressed separately in
            this Data Protection Declaration.</p>
          <Typography variant="h6" gutterBottom>Server log files</Typography>
          <p>The provider
            of this website and its pages automatically collects and stores
            information in so-called server log files, which your browser
            communicates to us automatically. The
            information comprises:</p>
          <ul>
            <li>The type and version of browser used</li>
            <li>The used operating system</li>
            <li>Referrer URL</li>
            <li>The hostname of the accessing computer</li>
            <li>The time of the server inquiry</li>
            <li>The IP address</li>
          </ul>
          <p>This data is not merged with other data sources.</p> <p>This data
          is
          recorded on the basis of Art. 6
          Sect. 1 lit. f GDPR. The operator of the website has a legitimate
          interest in the technically error free
          depiction and the optimization of the operator’s website. In order to
          achieve this, server log files must be
          recorded.</p>

          <Typography variant="h6" gutterBottom>Request by e-mail, telephone or
            fax</Typography> <p>If you contact us by e-mail, telephone or fax,
          your request,
          including all resulting personal data (name, request) will be stored
          and
          processed by us for the purpose of
          processing your request. We do not pass these data on without your
          consent.</p> <p>The processing of these
          data is based on Art. 6 para. 1 lit. b GDPR, if your request is
          related
          to the execution of a contract or if
          it is necessary to carry out pre-contractual measures. In all other
          cases, the processing is based on your
          consent (Article 6 (1) a GDPR) and/or on our legitimate interests
          (Article 6 (1) (f) GDPR), since we have a
          legitimate interest in the effective processing of requests addressed
          to
          us.</p> <p>The data sent by you to
          us via contact requests remain with us until you request us to delete,
          revoke your consent to the storage or
          the purpose for the data storage lapses (e.g. after completion of your
          request). Mandatory statutory
          provisions - in particular statutory retention periods - remain
          unaffected.</p>
          <Typography variant="h6" gutterBottom>Registration on this
            website</Typography>
          <p>You have the option to register on our website to be able to use
            additional website functions. We shall use the data you enter only
            for
            the purpose of using the respective
            offer or service you have registered for. The required information
            we
            request at the time of registration
            must be entered in full. Otherwise we shall reject the
            registration.</p> <p>To notify you of any important
          changes to the scope of our portfolio or in the event of technical
          modifications, we shall use the e-mail
          address provided during the registration process.</p> <p>We shall
          process the data entered during the
          registration process on the basis of your consent (Art. 6 Sect. 1 lit.
          a
          GDPR). You have the right to revoke
          at any time any consent you have already given us. To do so, all you
          are
          required to do is sent us an
          informal notification via e-mail. This shall be without prejudice to
          the
          lawfulness of any data collection
          that occurred prior to your revocation.</p> <p>The data recorded
          during
          the registration process shall be
          stored by us as long as you are registered on our website.
          Subsequently,
          such data shall be deleted. This
          shall be without prejudice to mandatory statutory retention
          obligations.</p>

          <Typography variant="h6" gutterBottom>Processing of data (customer and
            contract
            data)</Typography> <p>We collect, process and use personal data only
          to the extent necessary for the establishment, content organization or
          change of the legal relationship
          (data inventory). These actions are taken on the basis of Art. 6 Sect.
          1
          lit. b GDPR, which permits the
          processing of data for the fulfilment of a contract or pre-contractual
          actions. We collect, process and use
          personal data concerning the use of our website (usage data) only to
          the
          extent that this is necessary to
          make it possible for users to utilize the services and to bill for
          them.</p> <p>The collected customer data
          shall be eradicated upon completion of the order or the termination of
          the business relationship. This shall
          be without prejudice to any statutory retention mandates.</p>
          <Typography variant="h6" gutterBottom>Data transfer upon closing of
            contracts for
            online stores, retailers and the shipment of
            merchandise</Typography> <p>We share personal data with third
          parties
          only if this is necessary in conjunction
          with the handling of the contract; for instance, with companies
          entrusted with the shipment of goods or the
          financial institution tasked with the processing of payments. Any
          further transfer of data shall not occur
          or shall only occur if you have expressly consented to the transfer.
          Any
          sharing of your data with third
          parties in the absence of your express consent, for instance for
          advertising purposes, shall not occur.</p>
          <p>The basis for the processing of data is Art. 6 Sect. 1 lit. b GDPR,
            which permits the processing of data
            for the fulfilment of a contract or for pre-contractual actions.</p>
          <Typography variant="h6" gutterBottom>Data transfer upon closing of
            contracts for
            services and digital content</Typography> <p>We share personal data
          with third parties only if this is necessary in conjunction with the
          handling of the contract; for instance,
          with the financial institution tasked with the processing of
          payments.</p> <p>Any further transfer of data
          shall not occur or shall only occur if you have expressly consented to
          the transfer. Any sharing of your
          data with third parties in the absence of your express consent, for
          instance for advertising purposes, shall
          not occur.</p> <p>The basis for the processing of data is Art. 6 Sect.
          1
          lit. b GDPR, which permits the
          processing of data for the fulfilment of a contract or for
          pre-contractual actions.</p>

          <Typography variant="h4" gutterBottom>6. Analysis tools and
            advertising</Typography>
          <Typography variant="h6" gutterBottom>Google Analytics</Typography>
          <p>This website
            uses functions of the web analysis service Google Analytics. The
            provider of this service is Google Inc., 1600 Amphitheatre Parkway,
            Mountain View, CA 94043, USA.</p>
          <p>Google Analytics uses so-called cookies. Cookies are text files,
            which are stored on your computer and
            that enable an analysis of the use of the website by users. The
            information generated by cookies on your
            use of this website is usually transferred to a Google server in the
            United States, where it is
            stored.</p> <p>The storage of Google Analytics cookies and the
          utilization of this analysis tool are
          based on Art. 6 Sect. 1 lit. f GDPR. The operator of this website has
          a
          legitimate interest in the analysis
          of user patterns to optimize both, the services offered online and the
          operator’s advertising
          activities.</p> <p><strong>IP anonymization</strong></p> <p>On this
          website, we have activated the IP
          anonymization function. As a result, your IP address will be
          abbreviated
          by Google within the member states
          of the European Union or in other states that have ratified the
          Convention on the European Economic Area
          prior to its transmission to the United States. The full IP address
          will
          be transmitted to one of Google’s
          servers in the United States and abbreviated there only in exceptional
          cases. On behalf of the operator of
          this website, Google shall use this information to analyse your use of
          this website to generate reports on
          website activities and to render other services to the operator of
          this
          website that are related to the use
          of the website and the Internet. The IP address transmitted in
          conjunction with Google Analytics from your
          browser shall not be merged with other data in Google’s
          possession.</p>
          <p><strong>Browser plug-in</strong></p> <p>You do have the option to
          prevent the archiving of cookies by
          making pertinent changes to the settings of your browser software.
          However, we have to point out that in
          this case you may not be able to use all of the functions of this
          website to their fullest extent. Moreover,
          you have the option prevent the recording of the data generated by the
          cookie and affiliated with your use
          of the website (including your IP address) by Google as well as the
          processing of this data by Google by
          downloading and installing the browser plug-in available under the
          following link: <a
              href="https://tools.google.com/dlpage/gaoptout?hl=en"
              target="_blank"
              rel="noopener noreferrer">https://tools.google.com/dlpage/gaoptout?hl=en</a>.
        </p> <p><strong>Objection to the
          recording of data</strong></p> <p>You have the option to prevent the
          recording of your data by Google
          Analytics by clicking on the following link. This will result in the
          placement of an opt out cookie, which
          prevents the recording of your data during future visits to this
          website: <a href="javascript:gaOptout();">Google
            Analytics deactivation</a>.</p> <p>For more information about the
          handling of user data by Google
          Analytics, please consult Google’s Data Privacy Declaration at: <a
              href="https://support.google.com/analytics/answer/6004245?hl=de"
              target="_blank"
              rel="noopener noreferrer">https://support.google.com/analytics/answer/6004245?hl=en</a>.
        </p><p><strong>Contract
          data processing</strong></p> <p>We have executed a contract data
          processing agreement with Google and are
          implementing the stringent provisions of the German data protection
          agencies to the fullest when using
          Google Analytics.</p>
          <p><strong>Demographic parameters provided by Google
            Analytics</strong>
          </p> <p>This website uses the
          function “demographic parameters” provided by Google Analytics. It
          makes
          it possible to generate reports
          providing information on the age, gender and interests of website
          visitors. The sources of this information
          are interest-related advertising by Google as well as visitor data
          obtained from third party providers. This
          data cannot be allocated to a specific individual. You have the option
          to deactivate this function at any
          time by making pertinent settings changes for advertising in your
          Google
          account or you can generally
          prohibit the recording of your data by Google Analytics as explained
          in
          section “Objection to the recording
          of data.”</p>


          <Typography variant="h6" gutterBottom>Google AdSense</Typography>
          <p>This website
            uses Google AdSense, a service for the integration of ads offered by
            Google Inc. (&quot;Google&quot;). The provider of this service is
            Google Inc.,
            1600 Amphitheatre Parkway, Mountain
            View, CA 94043, USA.</p> <p>Google AdSense uses
          so-called &quot;cookies&quot;;
          i.e. text files that are stored on your
          computer and that make it possible to analyse your use of this
          website.
          Google AdSense also uses so-called
          Web Beacons (invisible graphics). These Web Beacons can be used to
          analyse information, such as visitor
          traffic data, on this website.</p> <p>The information generated by the
          cookies and the Web Beacons
          concerning the use of this website (including your IP address) and the
          delivery of advertising formats are
          transferred to a Google server in the United States, where they are
          archived. Google may share this
          information with Google contracting partners. Google shall, however,
          not
          merge your IP address with any
          other of your data stored by Google.</p> <p>The storage of the AdSense
          cookies is based on Art. 6 Sect. 1
          lit. f GDPR. The website operator has a legitimate interest in the
          analysis of user patterns, in order to
          optimize the operator’s web offerings and advertising.</p> <p>You can
          prevent the installation of these
          cookies by making a pertinent adjustment to your browser software
          settings; however, we have to point out
          that in this case you may not be able to use all of the functions of
          this website to their fullest extent.
          By using this website, you declare your consent to the processing of
          the
          data we collect about you by Google
          in the manner described above and agree to the aforementioned purpose
          of
          use.</p>
          <Typography variant="h6" gutterBottom>Google Analytics
            Remarketing</Typography>
          <p>Our websites use the functions of Google Analytics Remarketing in
            combination with the functions of Google AdWords and Google
            DoubleClick, which work on all devices. The
            provider of these solutions is Google Inc., 1600 Amphitheatre
            Parkway,
            Mountain View, CA 94043, USA.</p>
          <p>This function makes it possible to connect the advertising target
            groups generated with Google Analytics
            Remarketing with the functions of Google AdWords and Google
            DoubleClick, which work on all devices. This
            makes it possible to display interest-based customized advertising
            messages, depending on your prior
            usage and browsing patterns on a device (e.g. cell phone) in a
            manner
            tailored to you as well as on any
            of your devices (e.g. tablet or PC).</p> <p>If you have given us
          pertinent consent, Google will connect
          your web and app browser progressions with your Google account for
          this
          purpose. As a result, it is possible
          to display the same personalized advertising messages on every device
          you log into with your Google
          account.</p> <p>To support this function, Google Analytics records
          Google authenticated IDs of users that
          are temporarily connected with our Google Analytics data to define and
          compile the target groups for the ads
          to be displayed on all devices.</p> <p>You have the option to
          permanently object to remarketing / targeting
          across all devices by deactivating personalized advertising in your
          Google account. To do this, please
          follow this link: <a href="https://www.google.com/settings/ads/onweb/"
                               target="_blank"
                               rel="noopener noreferrer">https://www.google.com/settings/ads/onweb/</a>.
        </p> <p>The consolidation
          of the recorded data in your Google account shall occur exclusively
          based on your consent, which you may
          give to Google and also revoke there (Art. 6 Sect. 1 lit. a GDPR).
          Data
          recording processes that are not
          consolidated in your Google account (for instance because you do not
          have a Google account or have objected
          to the consolidation of data), the recording of data is based on Art.
          6
          Sect. 1 lit. f GDPR. The legitimate
          interest arises from the fact that the operator of the website has a
          legitimate interest in the anonymized
          analysis of website visitor for advertising purposes.</p> <p>For
          further
          information and the pertinent data
          protection regulations, please consult the Data Privacy Policies of
          Google at: <a
              href="https://policies.google.com/technologies/ads?hl=en"
              target="_blank"
              rel="noopener noreferrer">https://policies.google.com/technologies/ads?hl=en</a>.
        </p>
          <Typography variant="h6" gutterBottom>Google AdWords and Google
            Conversion
            Tracking</Typography> <p>This website uses Google AdWords. AdWords
          is
          an
          online promotional program of Google Inc., 1600 Amphitheatre Parkway,
          Mountain View, CA 94043, United States
          (“Google”).</p> <p>In conjunction with Google AdWords, we use a tool
          called Conversion Tracking. If you
          click on an ad posted by Google, a cookie for Conversion Tracking
          purposes will be placed. Cookies are small
          text files the web browser places on the user’s computer. These
          cookies
          expire after 30 days and are not
          used to personally identify users. If the user visits certain pages of
          this website and the cookie has not
          yet expired, Google and we will be able to recognise that the user has
          clicked on an ad and has been linked
          to this page.</p> <p>A different cookie is allocated to every Google
          AdWords customer. These cookies cannot
          be tracked via websites of AdWords customers. The information obtained
          with the assistance of the Conversion
          cookie is used to generate Conversion statistics for AdWords customers
          who have opted to use Conversion
          Tracking. The users receive the total number of users that have
          clicked
          on their ads and have been linked to
          a page equipped with a Conversion Tracking tag. However, they do not
          receive any information that would
          allow them to personally identify these users. If you do not want to
          participate in tracking, you have the
          option to object to this use by easily deactivating the Google
          Conversion Tracking cookie via your web
          browser under user settings. If you do this, you will not be included
          in
          the Conversion Tracking
          statistics.</p> <p>The storage of “Conversion” cookies and the use of
          this tracking tool are based on Art. 6
          Sect. 1 lit. f GDPR. The website operator has a legitimate interest in
          the analysis of user patterns, in
          order to optimize the operator’s web offerings and advertising.</p>
          <p>To review more detailed information
            about Google AdWords and Google Conversion Tracking, please consult
            the Data Privacy Policies of Google
            at: <a href="https://policies.google.com/privacy?hl=en"
                   target="_blank"
                   rel="noopener noreferrer">https://policies.google.com/privacy?hl=en</a>.
          </p> <p>You can set up your browser in
          such a manner that you will be notified anytime cookies are placed and
          you can permit cookies only in
          certain cases or exclude the acceptance of cookies in certain
          instances
          or in general and you can also
          activate the automatic deletion of cookies upon closing of the
          browser.
          If you deactivate cookies, the
          functions of this website may be limited.</p>

          <Typography variant="h4" gutterBottom>7. Newsletter</Typography>
          <Typography variant="h6" gutterBottom>Newsletter data</Typography>
          <p>If you would
            like to subscribe to the newsletter offered on this website, we
            will need from you an e-mail address as well as information that
            allow
            us to verify that you are the owner
            of the e-mail address provided and consent to the receipt of the
            newsletter. No further data shall be
            collected or shall be collected only on a voluntary basis. We shall
            use
            such data only for the sending of
            the requested information and shall not share such data with any
            third
            parties.</p> <p>The processing of the
          information entered into the newsletter subscription form shall occur
          exclusively on the basis of your
          consent (Art. 6 Sect. 1 lit. a GDPR). You may revoke the consent you
          have given to the archiving of data,
          the e-mail address and the use of this information for the sending of
          the newsletter at any time, for
          instance by clicking on the &quot;Unsubscribe&quot; link in the
          newsletter. This
          shall be without prejudice to the
          lawfulness of any data processing transactions that have taken place
          to
          date.</p> <p>The data you archive
          with us for the purpose of the newsletter subscription shall be
          archived
          by us until you unsubscribe from
          the newsletter. Once you cancel your subscription to the newsletter,
          the
          data shall be deleted. This shall
          not affect data we have been archiving for other purposes.</p>
          <Typography variant="h6" gutterBottom>MailChimp</Typography> <p>This
          website uses the
          services of MailChimp to send out its newsletters. The provider
          is the Rocket Science Group LLC, 675 Ponce De Leon Ave NE, Suite 5000,
          Atlanta, GA 30308, USA.</p> <p>Among
          other things, MailChimp is a service that can be deployed to organise
          and analyse the sending of
          newsletters. Whenever you enter data for the purpose of subscribing to
          a
          newsletter (e.g. your e-mail
          address), the information is stored on MailChimp servers in the United
          States.</p> <p>MailChimp is in
          possession of a certification that is in compliance with the
          “EU-US-Privacy-Shield.” The “Privacy-Shield” is
          a compact between the European Union (EU) and the United States of
          America (USA) that aims to warrant the
          compliance with European data protection standards in the United
          States.</p> <p>With the assistance of the
          MailChimp tool, we can analyse the performance of our newsletter
          campaigns. If you open an e-mail that has
          been sent through the MailChimp tool, a file that has been integrated
          into the e-mail (a so-called
          web-beacon) connects to MailChimp’s servers in the United States. As a
          result, it can be determined whether
          a newsletter message has been opened and which links the recipient
          possibly clicked on. Technical
          information is also recorded at that time (e.g. the time of access,
          the
          IP address, type of browser and
          operating system). This information cannot be allocated to the
          respective newsletter recipient. Their sole
          purpose is the performance of statistical analyses of newsletter
          campaigns. The results of such analyses can
          be used to tailor future newsletters to the interests of their
          recipients more effectively.</p> <p>If you do
          not want to permit an analysis by MailChimp, you must unsubscribe from
          the newsletter. We provide a link for
          you to do this in every newsletter message. Moreover, you can also
          unsubscribe from the newsletter right on
          the website.</p> <p>The data is processed based on your consent (Art.
          6
          Sect. 1 lit. a GDPR). You may revoke
          any consent you have given at any time by unsubscribing from the
          newsletter. This shall be without prejudice
          to the lawfulness of any data processing transactions that have taken
          place prior to your revocation.</p>
          <p>The data you archive with us for the purpose of the newsletter
            subscription shall be archived by us until
            you unsubscribe from the newsletter. Once you cancel your
            subscription
            to the newsletter, the data shall
            be deleted from our servers as well as those of MailChimp. This
            shall
            not affect data we have been
            archiving for other purposes.</p> <p>For more details, please
          consult
          the Data Privacy Policies of
          MailChimp at: <a href="https://mailchimp.com/legal/terms/"
                           target="_blank"
                           rel="noopener noreferrer">https://mailchimp.com/legal/terms/</a>.
        </p>
          <p><strong>Execution of a contract data processing agreement</strong>
          </p> <p>We have executed a so-called
          “Data Processing Agreement” with MailChimp, in which we mandate that
          MailChimp undertakes to protect the
          data of our customers and to refrain from sharing it with third
          parties.
          You may review this Agreement under
          the following link: <a
              href="https://mailchimp.com/legal/forms/data-processing-agreement/sample-agreement/"
              target="_blank"
              rel="noopener noreferrer">https://mailchimp.com/legal/forms/data-processing-agreement/sample-agreement/</a>.
        </p>
          <Typography variant="h4" gutterBottom>8. Plug-ins and
            Tools</Typography>
          <Typography variant="h6" gutterBottom>YouTube with expanded data
            protection
            integration</Typography> <p>Our website uses plug-ins of the YouTube
          platform, which is being operated by YouTube, LLC, 901 Cherry Ave.,
          San
          Bruno, CA 94066, USA.</p> <p>We use
          YouTube in the expanded data protection mode. According to YouTube,
          this
          mode ensures that YouTube does not
          store any information about visitors to this website before they watch
          the video. Nevertheless, this does
          not necessarily mean that the sharing of data with YouTube partners
          can
          be ruled out as a result of the
          expanded data protection mode. For instance, regardless of whether you
          are watching a video, YouTube will
          always establish a connection with the Google DoubleClick network.</p>
          <p>As soon as you start to play a
            YouTube video on our website, a connection to YouTube’s servers will
            be established. As a result, the
            YouTube server will be notified, which of our pages you have
            visited.
            If you are logged into your YouTube
            account while you visit our site, you enable YouTube to directly
            allocate your browsing patterns to your
            personal profile. You have the option to prevent this by logging out
            of your YouTube account.</p>
          <p>Furthermore, after you have started to play a video, YouTube will
            be
            able to place various cookies on
            your device. With the assistance of these cookies, YouTube will be
            able to obtain information about our
            website visitor. Among other things, this information will be used
            to
            generate video statistics with the
            aim of improving the user friendliness of the site and to prevent
            attempts to commit fraud. These
            cookies will stay on your device until you delete them.</p> <p>Under
          certain circumstances, additional
          data processing transactions may be triggered after you have started
          to
          play a YouTube video, which are
          beyond our control.</p> <p>The use of YouTube is based on our interest
          in presenting our online content in
          an appealing manner. Pursuant to Art. 6 Sect. 1 lit. f GDPR, this is a
          legitimate interest.</p> <p>For more
          information on how YouTube handles user data, please consult the
          YouTube
          Data Privacy Policy under: <a
              href="https://policies.google.com/privacy?hl=en" target="_blank"
              rel="noopener noreferrer">https://policies.google.com/privacy?hl=en</a>.
        </p>

          <Typography variant="h6" gutterBottom>Google Web Fonts (local
            embedding)</Typography>
          <p>This website uses so-called Web Fonts provided by Google to
            ensure the uniform use of fonts on this site. These Google fonts are
            locally installed so that a connection
            to Google’s servers will not be established in conjunction with this
            application.</p>


          <Typography variant="h6" gutterBottom>Google reCAPTCHA</Typography>
          <p>We use “Google
            reCAPTCHA” (hereinafter referred to as “reCAPTCHA”) on our
            websites. The provider is Google Inc., 1600 Amphitheatre Parkway,
            Mountain View, CA 94043, USA
            (“Google”).</p> <p>The purpose of reCAPTCHA is to determine whether
          data
          entered on our websites (e.g.
          information entered into a contact form) is being provided by a human
          user or by an automated program. To
          determine this, reCAPTCHA analyses the behaviour of the website
          visitors
          based on a variety of parameters.
          This analysis is triggered automatically as soon as the website
          visitor
          enters the site. For this analysis,
          reCAPTCHA evaluates a variety of data (e.g. IP address, time the
          website
          visitor spent on the site or cursor
          movements initiated by the user). The data tracked during such
          analyses
          are forwarded to Google.</p>
          <p>reCAPTCHA analyses run entirely in the background. Website visitors
            are not alerted that an analysis is
            underway.</p> <p>The data is processed on the basis of Art. 6 Sect.
          1
          lit. f GDPR. It is in the website
          operators legitimate interest, to protect the operator’s web content
          against misuse by automated industrial
          espionage systems and against SPAM.</p> <p>For more information about
          Google reCAPTCHA and to review the
          Data Privacy Declaration of Google, please follow these links: <a
              href="https://policies.google.com/privacy?hl=en" target="_blank"
              rel="noopener noreferrer">https://policies.google.com/privacy?hl=en</a> and <a
              href="https://www.google.com/recaptcha/intro/android.html"
              target="_blank"
              rel="noopener noreferrer">https://www.google.com/recaptcha/intro/android.html</a>.
        </p>


          <Typography variant="h4" gutterBottom>9. Online marketing and partner
            programmes</Typography>
          <Typography variant="h6" gutterBottom>Amazon partner
            programme</Typography> <p>The
          operators of this website participate in the Amazon EU partners’
          programme. Amazon integrates ads and links to the German Amazon
          website
          - Amazon.de - into our website,
          which allows us to generate earnings in the form of advertising cost
          reimbursements. Amazon uses cookies for
          this programme to be able to determine the source of orders placed.
          This
          allows Amazon to determine that you
          have clicked the partner link on our website.</p> <p>The archiving of
          “Amazon cookies” is based on Art. 6
          lit. f GDPR. Based on the aforementioned regulation, the website
          operator has a legitimate interest to do
          this since the determination of the website operator’s affiliate
          earnings is contingent upon the placement
          of cookies.</p> <p>For more information on Amazon’s data usage, please
          consult Amazon’s Data Privacy
          Declaration under the following link: <a
              href="https://www.amazon.com/gp/help/customer/display.html/ref=footer_privacy?ie=UTF8&nodeId=468496"
              target="_blank"
              rel="noopener noreferrer">https://www.amazon.com/gp/help/customer/display.html/ref=footer_privacy?ie=UTF8&nodeId=468496</a>.
        </p>
          <Typography variant="h4" gutterBottom>10. Payment service providers
            and
            resellers</Typography>
          <Typography variant="h6" gutterBottom>PayPal</Typography> <p>Among
          other options, we
          offer payment via PayPal on our website. The provider of this
          payment processing service is PayPal (Europe) S.à.r.l. et Cie, S.C.A.,
          22-24 Boulevard Royal, L-2449
          Luxembourg (hereinafter referred to as “PayPal”).</p> <p>If you choose
          payment via PayPal, we will share the
          payment information you enter with PayPal.</p> <p>The legal basis for
          the sharing of your data with PayPal
          is Art. 6 Sect. 1 lit. a GDPR (consent) as well as Art. 6 Sect. 1 lit. b
          GDPR (processing for the fulfilment
          of a contract). You have the option to at any time revoke your consent
          to the processing of your data. Such
          a revocation shall not have any impact on the effectiveness of data
          processing transactions that occurred in
          the past.</p>
        </Grid>
      </Grid>
  );
};

export default Privacy;
