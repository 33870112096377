import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Grid,
  LinearProgress,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Table,
} from '@material-ui/core';
import {Delete as DeleteIcon} from '@material-ui/icons';
import styles from '../Team/View/View.module.css';

const List = ({advice, adviceDeleteFun}) => {

  if (advice == null) {
    return (
        <LinearProgress/>
    );
  } else {

    return (
        <Grid container>
          <Grid item xs="12">
            <Typography variant="h5" component="h1"
                        gutterBottom>Your advice</Typography>
          </Grid>
          <Grid item xs="12">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="left">Date</TableCell>
                  <TableCell align="left">Question alias</TableCell>
                  <TableCell align="left">Name</TableCell>
                  <TableCell align="left">Advice</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {advice.map(advice => (
                    <TableRow key={advice.question_alias}>
                      <TableCell align="left">
                        {new Date(advice.created_at).toLocaleString()}
                      </TableCell>
                      <TableCell scope="row">
                        {advice.question_alias.charAt(0).toUpperCase() +
                        advice.question_alias.slice(1)}
                      </TableCell>
                      <TableCell scope="row">
                        {advice.name}
                      </TableCell>
                      <TableCell>
                        {advice.advice.length > 20 ? `${advice.advice.substring(
                            0,
                            20)}...` : advice.advice}
                      </TableCell>
                      <TableCell align="right">
                        <Button variant="contained" color="secondary"
                                onClick={() => {
                                  adviceDeleteFun(advice.hash);
                                }}
                        >
                          <DeleteIcon className={styles.rightIcon}/>
                        </Button>
                      </TableCell>
                    </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
    );
  }
};

List.propTypes = {
  advice: PropTypes.array,
  adviceDeleteFun: PropTypes.func.isRequired,
};

export default List;