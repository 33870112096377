/* Privacy */
export const LOGOUT = "LOGOUT";

/* User */
export const USER_UPDATE = "USER_UPDATE";

/* Teams */
export const TEAM_ADD = "TEAM_ADD";
export const TEAM_REMOVE = "TEAM_REMOVE";

/* Tracking */
export const TRACKING_SET = "TRACKING_SET";