import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
} from '@material-ui/core';
import {
  render_question,
} from '../../../utils/materialui-input';

const Part = (props) => {
  const {namespace, questions} = props;

  const question_elements = questions.map((question) =>
      render_question(namespace, question),
  );

  return (
      <Grid container direction="row"
            justify="flex-start"
            alignItems="flex-start" spacing={3}>
        {question_elements}
      </Grid>
  );
};

Part.propTypes = {
  namespace: PropTypes.string,
  questions: PropTypes.arrayOf(PropTypes.object),
};

export default Part;