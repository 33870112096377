import React from 'react';
import PropTypes from 'prop-types';
import List from '../../components/Advice/List';
import {apiAdviceDelete, apiAdviceGetOwn} from '../../api/AlignaClient';
import {updateUser} from '../../store/actions/User';
import {connect} from 'react-redux';

class AdviceListContainer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      user_hash: null,
      loading: false,
      advice: null,
    };
  }

  getAdvice = async (user_hash) => {
    apiAdviceGetOwn(user_hash).then((advice) => {
      this.props.updateUser({'hash': user_hash});
      this.setState({
        ...this.state,
        'advice': advice,
      });
    }).catch(() => {
      this.props.history.push('/oops');
    }).finally(() => {

    });
  };

  deleteAdvice = async (advice_hash) => {
    apiAdviceDelete(this.state.user_hash, advice_hash).then(() => {
      this.setState({
        advice: this.state.advice.filter(
            el => el.hash !== advice_hash),
      });

    }).catch(() => {

    }).finally(() => {

    });
  };

  componentDidMount() {
    const {user_hash} = this.props.match.params;
    this.setState({
      ...this.state,
      'user_hash': user_hash,
    });
    this.getAdvice(user_hash);
  }

  render() {
    return (
        <List advice={this.state.advice} adviceDeleteFun={this.deleteAdvice}/>
    );
  }
}

AdviceListContainer.propTypes = {
  updateUser: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      user_hash: PropTypes.string.isRequired,
    }),
  }).isRequired,
  history: PropTypes.object.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return {updateUser: user => dispatch(updateUser(user))};
};

export default connect(null, mapDispatchToProps)(AdviceListContainer);