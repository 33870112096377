import {
  TEAM_ADD,
  TEAM_REMOVE,
} from '../../constants/ActionTypes';

export function addTeam(team) {
  return {type: TEAM_ADD, team};
}

export function removeTeam(hash) {
    return {type: TEAM_REMOVE, hash:hash};
}