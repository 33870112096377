import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {initGA, PageView} from '../utils/tracking';

class TrackingComponent extends React.Component {

  componentDidMount() {
    initGA();
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged = () => {
    PageView();
  };

  render() {
    return null;
  }
}

TrackingComponent.propTypes = {
  location: PropTypes.object,
};

export const Tracking = withRouter(TrackingComponent);