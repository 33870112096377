import React from 'react';
import PropTypes from 'prop-types';
import {Formik, Form} from 'formik';
import {
  Grid,
  LinearProgress,
} from '@material-ui/core';
import TabsMenu from './TabsMenu';
import Details from './Details';
import Part from './Part';
import Stepper from './Stepper';
import TabPanel from './TabPanel';
import questionnaire_items from '../../../constants/Questionnaire/V1';

export const Create = props => {
  const {
    teamHash,
    changeTabFun,
    value,
    handleNextFun,
    handleBackFun,
    handleSubmitFun,
    validationSchema
  } = props;


  const CreateQuestionnaireForm = ({
    handleSubmit,
    handleChange,
    handleBlur,
    errors,
    touched,
    values,
    isSubmitting,
    status,
  }) => (
      <Form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {[
              'personality',
              'motivation',
              'time_and_money',
              'commitment',
              'team_work',
            ].map((namespace, index) =>
                <TabPanel value={value}
                          index={index}
                          key={`tabpanel-${index}`}
                >
                  <Part namespace={namespace}
                        key={`part-${index}`}
                        questions={questionnaire_items[namespace]}/>
                </TabPanel>,
            )}
            <TabPanel value={value} index={5}>
              <Details errors={errors} touched={touched}
                       isSubmitting={isSubmitting} status={status}/>
            </TabPanel>
          </Grid>

          <Grid item xs={12}>
            <Grid container justify="center">
              <Grid item xs={12} lg={4}>
                <Stepper nrSteps={6}
                         value={value}
                         handleNextFun={handleNextFun}
                         handleBackFun={handleBackFun}/>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Form>
  );

  CreateQuestionnaireForm.propTypes = {
    handleSubmit: PropTypes.func,
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
    values: PropTypes.object,
    errors: PropTypes.object,
    status: PropTypes.object,
    touched: PropTypes.object,
    isSubmitting: PropTypes.bool,
  };


  return (
      teamHash === null ? <LinearProgress/> :
          <Grid container spacing={3}>

            <Grid item xs={12}>
              <TabsMenu value={value} changeTabFun={changeTabFun}/>
            </Grid>

            <Grid item xs={12}>
              <Formik
                  initialValues={{
                    name: '',
                    email: '',
                    accept_terms: false,
                  }}
                  onSubmit={handleSubmitFun}
                  validationSchema={validationSchema}
                  isInitialValid={false}
                  component={CreateQuestionnaireForm}
              />
            </Grid>
          </Grid>
  );
};

Create.propTypes = {
  teamHash: PropTypes.string,
  changeTabFun: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
  handleNextFun: PropTypes.func.isRequired,
  handleBackFun: PropTypes.func.isRequired,
  handleSubmitFun: PropTypes.func.isRequired,
  validationSchema: PropTypes.any.isRequired,
};

export default Create;