import {
  TEAM_ADD,
  TEAM_REMOVE,
} from '../../constants/ActionTypes';

const initialState = {
  teams: [],
};

function teamReducer(state = initialState, action) {
  switch (action.type) {
    case TEAM_ADD: {
      const found = state.teams.some(
          team => team.hash === action.team.hash);
      if (!found) {
        return Object.assign({}, state, {
          teams: [
            ...state.teams,
            {
              alias: action.team.alias,
              hash: action.team.hash,
            },
          ],
        });
      }
      return state;
    }
    case TEAM_REMOVE: {
      return Object.assign({}, state, {
        teams:
            state.teams.filter(team => team.hash !== action.hash),
      });
    }
    default:
      return state;
  }
}

export default teamReducer;