import {LOGOUT} from '../../constants/ActionTypes';

export function deleteSession(ownProps) {
  return function(dispatch) {
    dispatch({
      type: LOGOUT,
      payload: undefined,
    });
    ownProps.history.push('/');
  };
}