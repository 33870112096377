import React from 'react';
import PropTypes from 'prop-types';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {
  Grid,
  Button,
  Typography,
  Box,
} from '@material-ui/core';
import {Share as ShareIcon, Email as EmailIcon} from '@material-ui/icons';
import styles from './View.module.css';
import {EmailShareButton} from 'react-share';

const Share = (props) => {

  const {link} = props;

  const email_subject = 'Lets align our team';
  const invite = "Hey there, \n\n" +
      "I want to invite you to check our team alignment with the help of Aligna. Simply fill out this form \n" +
      `${link}\n` +
      "After all team members have submitted their answers we can analyse our team alignment score with only one click. " +
      "It is super easy and takes only minutes.\n";

  return (
      <Grid container className={styles.shareSection} direction="row"
            alignItems="center">
        <Grid item xs={12}>
          <Box>
            <Typography component="h3" variant="h5"  gutterBottom>Invite your team</Typography>
            <Box style={{display: 'flex', alignItems: 'center'}}>
              <Box mr={1}>
                <CopyToClipboard text={invite}>
                  <Button
                      className={styles.button}
                      variant="contained"
                      color="primary">
                    Copy invite to clipboard
                    <ShareIcon className={styles.rightIcon}/>
                  </Button>
                </CopyToClipboard>
              </Box>
              <Box>
                <EmailShareButton subject={email_subject} body={invite}
                                  url={''}
                                  openWindow={true}>
                  <Button className={styles.button}
                          variant="contained"
                          color="primary">
                    Send invitation email
                    <EmailIcon/>
                  </Button>
                </EmailShareButton>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
  );
};

Share.propTypes = {
  link: PropTypes.string,
};

export default Share;