import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {withRouter} from 'react-router-dom';
import {deleteSession} from '../../store/actions/Privacy';
import {
  Divider,
  List,
  ListItemIcon,
  ListItem,
  ListItemText,
  Collapse,
  Box,
  Typography,
} from '@material-ui/core';
import {
  ExpandLess,
  ExpandMore,
  ExitToApp as LogoutIcon,
  List as TeamListIcon,
  Home as HomeIcon,
  GroupAdd as AddIcon,
  Group as TeamIcon,
  Favorite as AdviceIcon,
} from '@material-ui/icons';

/* UI Components */
import ShareButtons from '../ShareButtons';
import DonateButton from '../DonateButton';

const Menu = (props) => {

  const {
    style,
    user,
    teams,
    handleSurveysToggle,
    teamsExpandedState,
    deleteSession,
  } = props;

  const teamListDom = teams.map((team) =>
      <ListItem button className={style.nested} component={Link}
                to={'/team/' + team.hash + '/view'} key={team.hash}>
        <ListItemIcon><TeamIcon/></ListItemIcon>
        <ListItemText
            primary={team.alias.replace(/(.{10})..+/, '$1...')}
        />
      </ListItem>,
  );

  return (
      <React.Fragment>
        <div className={style.toolbar}>
          <img alt="Aligna Logo" className={style.logo}
               src={process.env.PUBLIC_URL + '/imgs/logo.svg'}/>
        </div>

        <Divider/>

        <List>
          <ListItem button key='Home' component={Link} to="/">
            <ListItemIcon><HomeIcon/></ListItemIcon>
            <ListItemText primary="Home"/>
          </ListItem>

          <ListItem button key='Create' component={Link} to="/team/create">
            <ListItemIcon><AddIcon/></ListItemIcon>
            <ListItemText primary="Create team"/>
          </ListItem>

          {teams.length > 0 ?
              <React.Fragment>
                {/* List of teams */}
                <ListItem button onClick={handleSurveysToggle}>
                  <ListItemIcon><TeamListIcon/></ListItemIcon>
                  <ListItemText primary="Teams"/>
                  {teamsExpandedState ? <ExpandLess/> : <ExpandMore/>}
                </ListItem>
                <Collapse in={teamsExpandedState} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {teamListDom}
                  </List>
                </Collapse>
              </React.Fragment>
              : null}
          {user.hash ?
              <React.Fragment>
                <Divider/>
                <ListItem button key='advice' component={Link}
                          to={`/advice/own/${user.hash}`}>
                  <ListItemIcon><AdviceIcon/></ListItemIcon>
                  <ListItemText primary="Your advice"/>
                </ListItem>
                <Divider/>
              </React.Fragment>
              : null}
          {teams.length > 0 || user.hash ?
              <React.Fragment>
                <ListItem button key='Index' onClick={deleteSession}>
                  <ListItemIcon><LogoutIcon/></ListItemIcon>
                  <ListItemText primary="Leave"/>
                </ListItem>
              </React.Fragment>
              : null}

        </List>

        <Box display="flex" flexGrow={1} flexDirection="row"
             alignItems="flex-end">
          <Box className={style.drawerBottomSection}>
            <Divider/>
            <Box textAlign="center" mt={2}>
              <Typography variant="body2">Support Our Mission</Typography>
            </Box>
            <ShareButtons/>
            <DonateButton/>
          </Box>
        </Box>
      </React.Fragment>
  );
};

Menu.propTypes = {
  style: PropTypes.object,
  user: PropTypes.object.isRequired,
  teams: PropTypes.arrayOf(PropTypes.object).isRequired,
  deleteSession: PropTypes.func.isRequired,
  handleSurveysToggle: PropTypes.func.isRequired,
  teamsExpandedState: PropTypes.bool,
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {deleteSession: () => dispatch(deleteSession(ownProps))};
};

export default withRouter(connect(null, mapDispatchToProps)(Menu));