import React from 'react';
import PropTypes from 'prop-types';
import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Paper,
  Typography,
  Grid,
  LinearProgress,
} from '@material-ui/core';
import {
  DeleteForever as DeleteIcon,
  BubbleChart as ChartIcon,
} from '@material-ui/icons';
import {PAGE_URL} from '../../../constants';
import ActionButton from '../../ActionButton';
import Share from './Share';
import Questionnaires from './Questionnaires';
import DialogAnalysis from './DialogAnalysis';
import DialogDelete from './DialogDelete';
import styles from './View.module.css';

const View = (props) => {

  const {
    team,
    questionnaires,
    closing,
    confirmAnalysisState,
    deleteTeamFun,
    closeTeamFun,
    questionnaireDeleteFun,
    confirmAnalysisFun,
    closeConfirmationFun,
    confirmDeletionState,
    confirmDeletionFun,
    closeDeletionFun,
    goToAnalysisFun,
    loading,
  } = props;

  const styles = theme => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
  });

  if (!team || loading) {
    return (
        <LinearProgress/>
    );
  }

  let date_formatted = new Date(team.created_at);

  return (
      team ?
          <React.Fragment>
            <DialogAnalysis
                confirmAnalysisState={confirmAnalysisState}
                closeTeamFun={closeTeamFun}
                closeConfirmationFun={closeConfirmationFun}
            />
            <DialogDelete
                confirmDeletionState={confirmDeletionState}
                deleteTeamFun={deleteTeamFun}
                closeDeletionFun={closeDeletionFun}
            />
            <Grid container direction="row"
                  alignItems="flex-start"
                  justify="space-between"
                  spacing={4}
            >
              {/* Header */}
              <Grid item xs={12}>
                <Typography variant="h4" gutterBottom>{team.alias}</Typography>
                <Typography variant="body2">
                  {date_formatted.toLocaleString()}
                </Typography>
              </Grid>
              {/* Share */}
              <Grid item xs={12}>
                <Paper square elevation={3}>
                  <Box p={3}>
                    <Grid container direction="row">
                      <Grid item xs={12} md={1}>
                        <img className={styles.team} alt="1."
                             src={process.env.PUBLIC_URL + '/imgs/1.png'}
                             style={{maxHeight: '70px'}}/>
                      </Grid>
                      <Grid item xs={12} md={11}>
                        <Share
                            link={PAGE_URL + 'team/' + team.hash +
                            '/questionnaires/create'}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={12} md={12}>
                <Paper square>
                  <Box p={3}>
                    <Grid container direction="row">
                      <Grid item xs={12} md={1}>
                        <img className={styles.team} alt="1."
                             src={process.env.PUBLIC_URL + '/imgs/2.png'}
                             style={{maxHeight: '70px'}}/>
                      </Grid>
                      <Grid item xs={12} md={11}>
                        <Questionnaires
                            team={team}
                            questionnaires={questionnaires}
                            questionnaireDeleteFun={questionnaireDeleteFun}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Paper square>
                  <Box p={3}>
                    <Grid container direction="row">
                      <Grid item xs={12} md={1}>
                        <img className={styles.team} alt="1."
                             src={process.env.PUBLIC_URL + '/imgs/3.png'}
                             style={{maxHeight: '70px'}}/>
                      </Grid>
                      <Grid item xs={12} md={11}>
                        <Grid container direction="column" justify="flex-start"
                              alignItems="flex-start" spacing={3}>
                          <Grid item xs={12}>
                            <Typography variant="h5">Get analysis</Typography>
                          </Grid>
                          {team.closed === false ?
                              <Grid item>
                                <Typography variant="subtitle2">Get the result
                                  now</Typography>
                                <Typography variant="body2"
                                            className={styles.helpText}>
                                  If everyone submitted his questionnaire and
                                  there
                                  are no incorrect
                                  ones in the list, request the Analysis by
                                  clicking
                                  below.
                                </Typography>
                                <ActionButton
                                    className={styles.button}
                                    loading={closing}
                                    disabled={questionnaires.length < 2}
                                    onClick={confirmAnalysisFun}
                                    variant='contained'
                                    color='primary'
                                >
                                  Get analysis
                                  <ChartIcon/>
                                </ActionButton>
                              </Grid>
                              :
                              <Grid item>
                                <ActionButton
                                    className={styles.button}
                                    onClick={goToAnalysisFun}
                                    variant='contained'
                                    color='primary'
                                >
                                  View analysis
                                  <ChartIcon/>
                                </ActionButton>
                              </Grid>
                          }
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center">
                  <Grid item xs={12} md={6}>
                    <Box mt={20}>
                      <BottomNavigation showLabels>
                        <BottomNavigationAction label="Delete team"
                                                icon={<DeleteIcon/>}
                                                onClick={confirmDeletionFun}/>
                      </BottomNavigation>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </React.Fragment>
          : null
  );
};

View.propTypes = {
  team: PropTypes.object,
  questionnaires: PropTypes.arrayOf(PropTypes.object),
  closing: PropTypes.bool,
  confirmAnalysisState: PropTypes.bool,
  deleteTeamFun: PropTypes.func.isRequired,
  closeTeamFun: PropTypes.func.isRequired,
  questionnaireDeleteFun: PropTypes.func.isRequired,
  confirmAnalysisFun: PropTypes.func.isRequired,
  closeConfirmationFun: PropTypes.func.isRequired,
  confirmDeletionState: PropTypes.bool,
  confirmDeletionFun: PropTypes.func.isRequired,
  closeDeletionFun: PropTypes.func.isRequired,
  goToAnalysisFun: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

export default View;
