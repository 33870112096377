import React from 'react';
import TitleComponent from '../TitleComponent';
import {Grid, Typography} from '@material-ui/core/';
import styles from './Error.module.css';

const Error = () => {
  return (
      <Grid container>
        <TitleComponent title={'Not Found'}/>
        <Grid item xs={12}>
          <Typography component="h1" variant="h4" align="center">
            Oooops, something went wrong
          </Typography>
          <img className={styles.travolta} alt="Comic character saying sorry"
               src={process.env.PUBLIC_URL + '/imgs/error.gif'}/>
        </Grid>
      </Grid>
  );
};

export default Error;
