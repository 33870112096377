import {SCORE_BAD_LIMIT, SCORE_GOOD_LIMIT} from '../constants';

export function parseJSON(response) {
    return response.data;
}

export function colorCodeScore(score) {
    let color = '#ffffff';
    if (score < SCORE_BAD_LIMIT) {
        color = '#EA497A';
    } else if (score <= SCORE_GOOD_LIMIT) {
        color = '#FFD450';
    } else {
        color = '#87E748';
    }
    return color;
}