import React from 'react';
import PropTypes from 'prop-types';
import ViewComponent from '../../components/Analysis/View';
import {
  apiAnalysisGet,
} from '../../api/AlignaClient';

class View extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      analysis: null,
      hash: null,
    };
  }

  loadData = (hash) => {
    apiAnalysisGet(hash).then(analysis => {
      this.setState({
        ...this.state,
        'analysis': analysis,
        'hash': hash
      });
    }).catch(() => {
      console.log('Failed to load analysis');
      // TODO: questionnaires failed to load?
    });
  };


  componentDidMount() {
    const {hash} = this.props.match.params;
    this.loadData(hash);
  }

  componentWillReceiveProps(newProps) {
    const {hash} = newProps.match.params;
    this.loadData(hash);
  }

  render() {
    return (
        <ViewComponent analysis={this.state.analysis}
        />
    );
  }
}

View.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      hash: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default View;