import React from 'react';
import PropTypes from 'prop-types';
import Details from './Details';
import {Typography, Grid, LinearProgress, Box} from '@material-ui/core';
import CategoryOverview from './CategoryOverview';
import CharacterTypes from './CharacterTypes';
import CriticalQuestions from './CriticalQuestions';
import questionnaire_items from '../../constants/Questionnaire/V1';

const View = (props) => {

  const {analysis} = props;
  if (analysis == null) {
    return (
        <LinearProgress/>
    );
  }

  let allQuestions = [];
  let allCategories = [];
  let allVoters = new Set();
  for (let [categoryName, category] of Object.entries(analysis.categories)) {
    allCategories.push(
        {name: categoryName.split('_').join(' '), score: category.score}
        );
    for (let [, question] of Object.entries(category.questions)) {
      // Get question
      const questionData = questionnaire_items[categoryName].filter(
          obj => obj.alias === question.alias)[0];
      // Set heading
      question.heading = questionData.heading;
      // Set option labels
      for (let [questionOptionName, questionOption] of Object.entries(question.distribution)) {
        questionOption.label = questionData.options.filter(
            option => option.handle === questionOptionName
        )[0].label;
        questionOption.voters.forEach((voter) => {allVoters.add(voter)});
      }
      allQuestions.push(question);
    }
    category.questions = category.questions.sort(
        (a, b) => parseFloat(a.score) - parseFloat(b.score));
  }
  allVoters = Array.from(allVoters);
  const categoryScores = allCategories.sort(
      (a, b) => parseFloat(a.score) - parseFloat(b.score));
  const questionsSorted = allQuestions.sort(
      (a, b) => parseFloat(a.score) - parseFloat(b.score));
  return (
      <Box>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h5" component="h1"
                        gutterBottom>Analysis</Typography>
          </Grid>
          <Grid item xs={12}>
            <CriticalQuestions questions={questionsSorted}/>
          </Grid>
          <Grid item xs={12}>
            <CharacterTypes character_types={analysis.character_types}/>
          </Grid>
          <Grid item xs={12}>
            <CategoryOverview categoryScores={categoryScores}/>
          </Grid>
          <Grid item xs={12}>
            <Details categories={analysis.categories} voters={allVoters}/>
          </Grid>
        </Grid>
      </Box>
  );
};

View.propTypes = {
  analysis: PropTypes.object,
};

export default View;